/*
  cogs.js
  A plugin to cross metadata with jumplist/chapterlist
  in order to create cogs.
*/

import { bb } from "$json";
import { getmetadata } from "$json/lib/getmetadata";
import { state } from "$json/lib/store";
import Chart from "chart.js/auto";
import { converter } from "$md-converter";
import { has } from "$json/lib/functional";

((doc, win, $) => {
  var fresh = true;

  const applystate = () => {
    // When called, run through the state and apply UI critical states.
    bb.Mode.toggle("hasCogs", state.has("cogs"));
    bb.Mode.toggle("inAnalysis", state.get("analysis"));
    bb.Mode.toggle(
      "isCompleted",
      state.has("completed") && state.get("completed")
    );

    if (state.has("overviewnode")) {
      doc.querySelectorAll('[data-action="go-to-cogs"]').forEach(button => {
        button.setAttribute("data-groupid", state.get("overviewnode"));
      });
    }

    if (state.has("resultnode")) {
      doc
        .querySelector(".resultjumper-button")
        .setAttribute("data-groupid", state.get("resultnode"));
    }
  };

  const colors = [
    "rgba(255,64,27,.7)",
    "rgba(251,117,14,.7)",
    "rgba(232,204,17,.7)",
    "rgba(64,255,86,.7)"
  ];

  $(doc).on("bb:stateChange", (e, data) => {
    applystate();
  });

  $(doc).on("bb:preHandleData", (e, data) => {
    // In case restart was used, reset all states
    if (data && has("hasprevious")(data) && !data.hasprevious) {
      bb.Mode.unset("inChapter");
      bb.Mode.unset("inResult");
      bb.Mode.unset("hasProgress");
    }
    // Process the metadata for this action.
    if (data && data.groups && data.groups.length) {
      // Get metadata
      let md = getmetadata(data).props;

      // Get chapterid and groupid of current group.
      let group = data.groups[data.groups.length - 1];
      let chapterid = group.chapterid;
      let groupid = group.groupid;

      // Maintain a ref list of full name to goupid.
      state.set(group.groupid, group.name);

      // In case we are in the analysis questions:
      state.set("analysis", md.has("analysis"));

      // If all questions are ansered, the model will inform us.
      state.set("completed", state.has("completed") && state.get("completed"));

      // If cog1 exists, then we have cogs.
      if (md.has("cog1") && !state.has("cogs")) {
        // The node we are currently on should be the go-to node to get back to the chapters.
        state.set("overviewnode", groupid);
        // Also set the result jumper
        data.jumplist.forEach(item => {
          if (item.screentitle === "Resultaat") {
            state.set("resultnode", item.groupid);
          }
        });

        let cogs = {};
        md.forEach((potential, prop) => {
          if (prop.includes("cog")) {
            let cog = JSON.parse(potential);
            cogs[cog.name] = cog;
          }
        });
        state.set("cogs", cogs);

        if (data.hasOwnProperty("jumplist")) {
          data.jumplist.forEach(item => {
            if (cogs.hasOwnProperty(item.screentitle.toLowerCase()))
              cogs[item.screentitle.toLowerCase()].groupid = item.groupid;
          });
        }
      }

      // If we're in a chapter, record the data.
      if (chapterid !== "" && md.size) {
        if (md.has("segments")) {
          state.set(chapterid, md.get("segments"));
        }
      }

      // If we have a metadata entry 'back-to-cogs',
      // it means the bb-next button sould be replaced by a button that
      // gotonode setcogs.
      bb.Mode.toggle("goBack", md.has("back-to-cogs"));

      applystate();
    }
  });

  $(doc).on("bb:finalHandleData", (e, data) => {
    if (data && data.groups && data.groups.length) {
      // Depending on the stage of the model, scroll to specific point:
      if (
        data.groups[data.groups.length - 1].name !== "gregelhulp.introductie" &&
        data.groups[data.groups.length - 1].name !== "gconclusie.resultaat" &&
        !data.groups[data.groups.length - 1].name.includes("ganalysis")
      ) {
        if (data.groups[data.groups.length - 1].name.includes("gmaturity")) {
          window.setTimeout(() => {
            doc.querySelector(".p-metaprogress").scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest"
            });
          }, 100);
        } else {
          window.setTimeout(() => {
            doc.querySelector(".columns").scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest"
            });
          }, 100);
        }
      } else {
        window.setTimeout(() => {
          window.scrollTo(0, 0);
        }, 100);
      }
      let cogs = state.has("cogs") ? state.get("cogs") : false;
      // Get chapterid and groupid of current group.
      let group = data.groups[data.groups.length - 1];
      let chapterid = group.chapterid;
      let groupid = group.groupid;
      let cogsjumplist = [...doc.querySelectorAll(".cogs.bb-jumplist li")];
      let piejumplist = () => [...doc.querySelectorAll(".pie.bb-jumplist li")];
      // Set mode if we are in chapter.
      let show = false;
      let showresult = false;
      let chapterImage;
      if (!cogs) {
        // Do the pre-cog text changes.
        // If we're not in a chapter or result page, move the text from bbq to q-content;
        let dbarea = doc.querySelector(".initial-explanation.hml");
        let dbstatic = dbarea.querySelector(".static-text");
        let dbdynamic = dbarea.querySelector(".dynamic-text");
        let dbtitlearea = dbarea.querySelector(".dbtitle");
        let dbtextarea = dbarea.querySelector(".dbtext");

        // Reset
        dbstatic.style.display = "none";
        dbdynamic.style.display = "none";
        dbtitlearea.innerHTML = "";
        dbtextarea.innerHTML = "";

        let dbtitles = doc.querySelectorAll(
          "#bb-q fieldset .bbm-darkblue-title"
        );
        dbtitles.length && dbtitlearea.append(...dbtitles);

        let dbtext = doc.querySelectorAll("#bb-q fieldset .bbm-darkblue-text");
        dbtext.length && dbtextarea.append(...dbtext);

        if (dbtitles.length || dbtext.length) {
          dbdynamic.style.display = "block";
        } else {
          dbstatic.style.display = "flex";
        }
      } else {
        Object.keys(cogs).forEach(cog => {
          if (cogs[cog].groupid === group.chapterid) {
            show = true;
            chapterImage = `${cogs[cog].name
              .split(" ")
              .join("-")}-inner-dark.png?v=20221117`;
          } else if (group.chapterid === state.get("resultnode")) {
            showresult = true;
            chapterImage = `blank.png?v=20221117`;
          }
        });
        bb.Mode.toggle("inChapter", show);
        bb.Mode.toggle("inResult", showresult);
        // Make the chapter name accessible to CSS
        document.styleSheets[0].addRule(
          ".cogs-portal #bb-q::before",
          `background-image: url('custom/agroconnect/plugins/cogs/live/assets/${chapterImage}') !important;`
        );

        if (cogsjumplist.length) {
          cogsjumplist.forEach((li, i) => {
            let btn = li.querySelector("button");
            let name = btn.innerText.toLowerCase();
            if (cogs.hasOwnProperty(name)) {
              let level = cogs[name].level;
              let scale = level * 10 + 150;
              btn.style.backgroundImage = `url('./custom/agroconnect/plugins/cogs/live/assets/${cogs[name].image}?v=20221117')`;
              li.style.width = `${scale}px`;
              li.style.height = `${scale}px`;
              li.setAttribute("idx", i);
            } else {
              li.parentNode.removeChild(li);
            }
          });
          // Now kill the connection between core and cogsjumplist
          let bbjumplist = doc.querySelector(".cogs.bb-jumplist");
          bbjumplist.classList.add("faux-jumplist");
          bbjumplist.classList.remove("bb-jumplist");
        }

        // Force scoresUpdated to ensure the visuals are correct after refresh.
        if (fresh) {
          $(document).trigger("bb:stateChange");
          fresh = false;
        }

        // If we're in a chapter, and showing the portal, set up the pie.
        if (show) {
          // Prep the pie if there is one.
          // By removing any items not related to current chapter.
          let fromhere = false;
          piejumplist().forEach(li => {
            let btn = li.querySelector("button");
            let btnchapterid = btn.getAttribute("data-groupid");
            if (!fromhere) {
              if (btnchapterid === chapterid) {
                // This is our chapter.
                fromhere = true;
              }
              li.parentNode.removeChild(li);
            } else {
              if (btnchapterid.indexOf(".") !== -1) {
                // This is the next chapter heading.
                fromhere = false;
                li.parentNode.removeChild(li);
              }
            }
          });

          // Do we have a segment count?
          let segments =
            chapterid === ""
              ? false
              : state.has(chapterid)
              ? state.get(chapterid)
              : 6; // Default, if it was not defined. Naughty modeller.

          if (segments) {
            // If false, there's no chapter yet.
            // Prep the segments.

            let slicecount = piejumplist().length;
            let requiredslices = segments;

            // In case the modeller made a mistake.
            if (requiredslices < slicecount) requiredslices = slicecount;

            if (slicecount < requiredslices) {
              for (let i = requiredslices - slicecount; i >= 1; i--) {
                let fauxitem = doc.createElement("li");
                fauxitem.className = "fauxitem";
                let fauxbutton = doc.createElement("button");
                fauxitem.append(fauxbutton);
                doc.querySelector(".pie.bb-jumplist ul").appendChild(fauxitem);
              }
            }

            let selectedPos = 0;
            let share = 360 / requiredslices;
            let correction;
            piejumplist().forEach((li, i) => {
              // Move the btn text inside a wrapper, for the sake of media query which rotates for mobile view.
              let wrapper = doc.createElement("span");
              wrapper.className = "wrapper";
              li.append(wrapper);
              wrapper.append(li.querySelector("button"));

              if (li.classList.contains("selected")) {
                selectedPos = i + 1;
              }

              if (share === 360) {
                li.classList.add("fullcircle");
                correction = 0;
              } else if (share === 180) {
                li.classList.add("semicircle");
                li.style.setProperty(
                  "transform",
                  `translate(${-i * 100}%, 0px) rotate(${i * 180}deg)`
                );
                correction = -(90 - share / 2);
              } else {
                li.style.setProperty(
                  "transform",
                  `rotate(${i * share}deg) skewY(${share - 90}deg)`
                );
                correction = -(90 - share / 2);
                // Based on number of segments, postion the wrapper.
                let leftpos = 0;
                let smladjust = "0px";
                if (segments >= 2 && segments <= 26) {
                  /*
                    Yes, this is the only was I could get the left position.
                    By looking at the known pies (<--) we can guess what
                    left postion should be for any other within the range 2:26.
                    I'm sure I could have made a fancy logarithmic function, but
                    there was no time.

                    26 segments =  0px <--
                    25 segments =  0px
                    24 segments =  1px
                    23 segments =  2px
                    22 segments =  3px <--
                    21 segments =  4px
                    20 segments =  5px
                    19 segments =  6px
                    18 segments =  7px
                    17 segments =  8px
                    16 segments = 10px <--
                    15 segments = 12px <--
                    14 segments = 14px <--
                    13 segments = 14px <--
                    12 segments = 15px <--
                    11 segments = 18px
                    10 segments = 21px <--
                     9 segments = 25px
                     8 segments = 29px
                     7 segments = 33px <--
                     6 segments = 37px
                     5 segments = 45px <--
                     4 segments = 49px <--
                     3 segments = 55px
                     2 segments = 60px
                  */
                  let leftpositions = {
                    26: 0,
                    25: 0,
                    24: 1,
                    23: 2,
                    22: 3,
                    21: 4,
                    20: 5,
                    19: 6,
                    18: 7,
                    17: 8,
                    16: 10,
                    15: 12,
                    14: 14,
                    13: 14,
                    12: 15,
                    11: 18,
                    10: 21,
                    9: 25,
                    8: 29,
                    7: 33,
                    6: 37,
                    5: 45,
                    4: 49,
                    3: 55,
                    2: 60
                  };
                  let smladjustments = {
                    26: -1,
                    25: -1,
                    24: -2,
                    23: -2,
                    22: -2,
                    21: -2,
                    20: -3,
                    19: -3,
                    18: -3,
                    17: -3,
                    16: -4,
                    15: -5,
                    14: -7,
                    13: -7,
                    12: -7,
                    11: -8,
                    10: -8,
                    9: -9,
                    8: -10,
                    7: -10,
                    6: -12,
                    5: -14,
                    4: -15,
                    3: -18,
                    2: -20
                  };
                  leftpos = leftpositions[segments];
                  smladjust = smladjustments[segments];
                }

                li.querySelector(".wrapper").style.setProperty(
                  "transform",
                  `skewY(${-(share - 90)}deg) rotate(${correction}deg)`
                );
                li.querySelector(".wrapper").style.setProperty(
                  "left",
                  leftpos + "px"
                );
                li.querySelector(".wrapper").style.setProperty(
                  "--smladjust",
                  smladjust + "px"
                );
              }
            });

            // Rotate the pie so that the selected item faces the question.
            let rdeg = selectedPos
              ? share === 360
                ? 0
                : 90 - (selectedPos * share - share / 2)
              : 0;
            doc
              .querySelector(".pie.bb-jumplist")
              .style.setProperty("transform", `rotate(${rdeg}deg)`);
          }
        }

        // When in the result page, form the grid to match design.
        if (showresult) {
          // And collate the data for the chart.
          let fontSize = Math.round(window.innerWidth / 50);
          fontSize = fontSize > 16 ? 16 : fontSize;
          fontSize = fontSize < 9 ? 9 : fontSize;
          let chart = {
            options: {
              maintainAspectRatio: false,
              plugins: {
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label: function (context) {
                      return Math.round((Number(context.raw) / 4) * 100) + " %";
                    },
                    labelColor: function (context) {
                      return {
                        borderColor: "rgba(0, 0, 0, 0)",
                        backgroundColor: colors[context.raw - 1]
                      };
                    }
                  }
                },
                legend: {
                  display: false
                }
              },
              scales: {
                r: {
                  angleLines: {
                    display: true
                  },
                  min: -1,
                  max: 4,
                  ticks: {
                    stepSize: 5,
                    callback: function () {
                      return "";
                    },
                    backdropColor: "rgba(0, 0, 0, 0)"
                  },
                  pointLabels: {
                    color: "black",
                    font: {
                      size: fontSize
                    }
                  }
                }
              }
            },
            type: "radar",
            data: {
              datasets: [
                {
                  data: [],
                  labels: [],
                  backgroundColor: "grey",
                  lineTension: 0
                }
              ],
              labels: []
            }
          };

          let resultwrapper = doc.createElement("div");
          resultwrapper.className = "resultwrapper";

          let table = doc.querySelector("#bb-q fieldset table");
          let rows = table.querySelectorAll("tbody tr");

          rows.forEach((row, i) => {
            let score = row.querySelector('[data-column="Score"]').innerText;
            score = isNaN(score) ? "0" : score;
            let themeCell = row.querySelector('[data-column="Thema"]');
            let themeString = themeCell.innerText
              .trim()
              .toLowerCase()
              .split(" ")
              .join("-");
            let text = row.querySelector(
              '[data-column="Toelichting"]'
            ).innerHTML;

            // let extraText = row.querySelector(
            //   '[data-column="Nadere toelichting"]'
            // ).innerHTML;
            let extraTextRaw = $(
              row.querySelector(
                '[data-column="Nadere toelichting"] > div.bb-label'
              )
            ).data("control").value;
            extraTextRaw = extraTextRaw.replaceAll(
              "|bulb|",
              '<div class="bulb">'
            );
            extraTextRaw = extraTextRaw.replaceAll("|/bulb|", "</div>");
            extraTextRaw = extraTextRaw.replaceAll(
              "|tick|",
              '<div class="tick">'
            );
            extraTextRaw = extraTextRaw.replaceAll("|/tick|", "</div>");
            let extraText = converter.makeHtml(extraTextRaw);
            let extraTextTemplate =
              extraText.trim() !== ""
                ? `
                <label for="${themeString}-toggler">

                </label>
              `
                : "";
            let jumpToCogs =
              score < "4"
                ? `
              <button class="jump-to-cogs" data-groupid="${state.get(
                "overviewnode"
              )}" type="submit" name="gotonode" form="bb-q">
                Back to overview
              </button>
            `
                : "";
            let itemtext = text + jumpToCogs;

            score = score.trim();
            if (score >= 1 && score <= 4) {
              document.styleSheets[0].addRule(
                `body.inResult .cogs-portal #bb-q .resultwrapper .itemwrapper[data-row="${
                  i + 1
                }"] .item.score::before`,
                `background-image: url('custom/agroconnect/plugins/cogs/live/assets/cog-score-${score}.png?v=20221117')`
              );
            }
            let item = `
              <div class="itemwrapper" data-row="${i + 1}">
                <input id="${themeString}-toggler" type="checkbox" class="a-offscreen toggle-info p-custom-checkboxes" aria-label="more information" />
                <div class="item score">
                  <div
                    class="themeImg"
                    role="img"
                    aria-label="${themeCell.innerText} score: ${score}">
                  </div>
                </div>
                <div class="item text">
                  <h2>${themeCell.innerText}</h2>
                  ${itemtext}
                  ${extraTextTemplate}
                </div>
                <div class="extra-info">
                  ${extraText}
                </div>
              </div>
            `;
            document.styleSheets[0].addRule(
              `body.inResult .cogs-portal #bb-q .resultwrapper .itemwrapper[data-row="${
                i + 1
              }"] .item.score .themeImg::before`,
              `background-image: url('custom/agroconnect/plugins/cogs/live/assets/${themeString}-inner.png?v=20221117')`
            );

            resultwrapper.insertAdjacentHTML("beforeend", item);
            chart.data.labels.push(themeCell.innerText);
            chart.data.datasets[0].labels.push(themeCell.innerText);
            chart.data.datasets[0].data.push(Number(score));
          });

          // If not all chapters are answered, the chart should be blue.
          if (chart.data.datasets[0].data.includes(0)) {
            chart.data.datasets[0].backgroundColor = "rgba(33, 154, 214, 0.7)";
          } else {
            // Get the bg color based on average score of all chapters.
            let average = Math.round(
              chart.data.datasets[0].data.reduce((acc, cv) => acc + cv) /
                chart.data.datasets[0].data.length
            );
            average = average === 0 ? 1 : average;
            chart.data.datasets[0].backgroundColor = colors[average - 1];
          }

          let chartCanvas = doc.createElement("canvas");
          let chartObj = new Chart(chartCanvas, chart);
          // And now create the chart.
          let chartcontainer = doc.createElement("div");
          chartcontainer.className = "graph";

          // Also create special nav buttons.
          let nav = doc.createElement("nav");
          nav.insertAdjacentHTML(
            "beforeend",
            `
            <button class="secondary" data-groupid="${state.get(
              "overviewnode"
            )}" type="submit" name="gotonode" form="bb-q">Vorige</button>
            <span id="end-tools-special-container"></span>
          `
            // <a class="primary orange" href="https://www.digitaltrustcenter.nl/aan-de-slag-met-ics-security">Aan de slag!</a>
          );

          // Finally do the DOM stuff
          chartcontainer.appendChild(chartCanvas);
          doc.querySelector("#bb-q fieldset").appendChild(chartcontainer);
          doc.querySelector("#bb-q fieldset").appendChild(resultwrapper);
          doc.querySelector("#bb-q").appendChild(nav);
          table.style.display = "none"; //IE
          table.remove();
          // Move the end tools to the fake nav;
          let target = doc.getElementById("end-tools-special-container");
          target.appendChild(doc.querySelector(".bb-p-end-tools-container"));
        }
      }
      applystate();
    }
  });

  $(doc).on("bb:scoresUpdated", (_e, scoreMap) => {
    if (scoreMap.size) {
      // Run through the cogs and pies and update design based on score.
      let cogsjumplist = [
        ...doc.querySelectorAll(".cogs.faux-jumplist li, .cogs.bb-jumplist li")
      ];
      let piejumplist = [...doc.querySelectorAll(".pie.bb-jumplist li")];

      cogsjumplist.forEach(cog => {
        let btn = cog.querySelector("button");
        let score = btn.getAttribute("data-final-score");
        let average = Math.floor(btn.getAttribute("data-average-score"));
        let idx = cog.getAttribute("idx");
        if (idx && average >= 1 && average <= 5 && Number(score)) {
          document.styleSheets[0].addRule(
            `.cogs.faux-jumplist ul li[idx="${idx}"]::before`,
            `background-image: url('custom/agroconnect/plugins/cogs/live/assets/cog-score-${average}.png?v=20221117')`
          );
        }
      });

      piejumplist.forEach(slice => {
        if (!slice.classList.contains("fauxitem")) {
          let score = slice.querySelector("button").getAttribute("data-score");
          if (score >= 1 && score <= 5) {
            slice.style.setProperty("background-color", colors[score - 1]);
          }
        }
      });

      // If all chapters have a final score, we can say that all questions
      // have been answered. Then set the 'completed' flag to true.
      let totalChapters = cogsjumplist.length;
      let completed = 0;
      scoreMap.forEach(score => {
        if (typeof score === "object") {
          // this is a chapter.
          if (has("final")(score) && score.final !== 0) completed++;
        }
      });
      state.set("completed", completed === totalChapters);
      applystate();
    }
  });
})(document, window, jQuery);
