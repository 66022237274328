/*
 * metaprogress.js
 *
 * A plugin to create and maintain a
 * progress indicator, updated by
 * metadata.
 *
 * NB: Layer requires at least one
 *     element of class 'p-metaprogress'
 *
 * Tip:
 * You can provide a range, e.g. "setmetatdata('progress','3:5')"
 * This will cause the plugin to fill the element(s) with
 * 5 spans, the first two will have class 'complete'
 * the third will have class 'current' and the last
 * two will have class 'future'.
 *
 * If you need all steps to be 'complete', you can:
 * setmetadata('progress', 'n+1:n');
 */

import { getmetadata } from "$json/lib/getmetadata";
import { _, bb } from "$json";

const DICTIONARY = {
  "Your progress so far is": {
    "en": "Your progress so far is",
    "nl": "Je voortgang tot nu toe is",
    "en-GB": "Your progress so far is"
  },
  "You are currently on step": {
    "en": "You are currently on step",
    "nl": "U bevindt zich momenteel bij stap",
    "en-GB": "You are currently on step"
  },
  "of": {
    "en": "of",
    "nl": "van",
    "en-GB": "of"
  }
};
_.addTranslations(DICTIONARY);

((doc, $) => {
  // Add a ARIA notify block for read-aloud progress reports.
  const topIndicator = doc.querySelector(".p-metaprogress");
  const notifier = doc.createElement("span");
  notifier.setAttribute("aria-live", "assertive");
  notifier.setAttribute("aria-atomic", "true");
  notifier.setAttribute("aria-relevant", "additions");
  notifier.classList.add("a-offscreen");
  topIndicator.insertAdjacentElement("afterend", notifier);

  $(doc).on("bb:postHandleData", (e, data) => {
    notifier.textContent = ""; // Wipe
    if (data && data.groups && data.groups.length) {
      const md = getmetadata(data).props;
      const indicators = doc.querySelectorAll(".p-metaprogress");
      if (md.has("progress")) {
        bb.Mode.set("hasProgress");
        let progress = md.get("progress");
        indicators.innerHTML = "";
        let inner = false;
        let announcement = "";
        if (progress === "hide") {
          bb.Mode.unset("hasProgress");
        }
        if (progress.includes(":")) {
          // The model is trying to give a range. Add spans for each potential step.
          inner = doc.createElement("div");
          inner.className = "steps";
          let range = Number(progress.split(":").pop());
          let current = Number(progress.split(":")[0]);
          announcement = `${_("You are currently on step")} ${current} ${_(
            "of"
          )} ${range}`;
          try {
            for (let i = range; i > 0; i--) {
              let span = doc.createElement("span");
              span.className = "step";
              i < current && span.classList.add("complete");
              i === current && span.classList.add("current");
              i > current && span.classList.add("future");
              span.setAttribute("data-step", i);
              inner.prepend(span);
            }
          } catch (err) {
            return;
          }
        } else {
          announcement = `${_("Your progress so far is")}: ${progress}%`;
        }
        indicators.forEach(indicator => {
          indicator.innerText = progress;
          if (inner) {
            indicator.innerHTML = "";
            indicator.append(inner);
          }
          indicator.setAttribute("data-progress", md.get("progress"));
          indicator.style.setProperty("--progress", md.get("progress"));
        });
        // Announce
        notifier.textContent = announcement;
      }
    }
  });
})(document, jQuery);
