import {
  assoc,
  hasPath,
  chain,
  compose,
  either,
  path,
  prop,
  toLower,
  toUpper,
  ifElse,
  juxt,
  when
} from "./functional.js";
import { format } from "./text-utils.js";
import { _ } from "./gettext.js";

const fieldDesignators = {
  datetimepicker: "field--date",
  numedit: "field--value",
  radio: "field--list",
  checkmultilist: "field--list",
  customlist: "field--list",
  combobox: "field--list",
  listbox: "field--list",
  multilist: "field--list",
  any: "field--field"
};

const fieldKey = compose(
  controltype => fieldDesignators[controltype] || fieldDesignators["any"],
  prop("controltype")
);

const generic = compose(key => _(key), fieldKey);

const genericQuoted = compose(
  key => _(key), // Runtime!
  key => key.replace("field--", "quoted--"),
  fieldKey
);
export const quotable = compose(
  when(
    prop("quotable"),
    chain(
      assoc("quotable--init"),
      compose(s => s.slice(0, 1).toUpperCase() + s.slice(1), prop("quotable"))
    )
  ),
  when(
    prop("quotable"),
    chain(assoc("quotable--upper"), compose(toUpper, prop("quotable")))
  ),
  when(
    prop("quotable"),
    chain(assoc("quotable--lower"), compose(toLower, prop("quotable")))
  ),
  chain(assoc("quotable"), either(path(["metadata", "quotable"]), generic)),
  ifElse(
    hasPath(["metadata", "quotable"]),
    compose(assoc("<<", _("<<", "«")), assoc(">>", _(">>", "»"))),
    compose(assoc("<<", ""), assoc(">>", ""))
  ),
  chain(
    assoc("quoted--init"),
    compose(s => s.slice(0, 1).toUpperCase() + s.slice(1), prop("quoted"))
  ),
  ifElse(
    hasPath(["metadata", "quotable"]),
    chain(
      assoc("quoted"),
      compose(
        arr => format(...arr),
        juxt([
          genericQuoted,
          compose(quotable => ({ quotable }), path(["metadata", "quotable"]))
        ])
      )
    ),
    chain(assoc("quoted"), generic)
  )
);
