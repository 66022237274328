import { path, either, compose, prop } from "./functional.js";
import { conf } from "./conf.js";

/**

Name keys follow this pattern:

<ELEMENT>_<TYPE>_<SEMANTICS>

<ELEMENT> is any of:

- GROUPING: subgroup of questions and/or texts and/or links
- ITEM: a question and/or text and/or link
- QUESTION: a question (is also an item)
- TEXT: a top level text (not a label for a question)
- LINK: a top level link (so: not inside markdown)
- PICTURE: a top level image (so : nto inside markdown)

GROUPING ::= ITEM+
ITEM ::= QUESTION | TEXT | LINK | PICTURE

<TYPE> is any of:

- _CLASS_ : the css class to put on this type <ELEMENT>
- _PREFIX_: a css class prefix
- _DATA_: a data- attribute

where <SEMANTICS> can be anything.

The default values for those keys, thus the strings that willl be used
in the generated DOM, are provided in nameDefaults. They can be
overwritten in the `conf.json` file in the property
`core.form-group.names` in order to retrofit older or other naming
schemes.

*/

export const /* A grouping groups items */
  GROUPING_CLASS = "grouping-class", // Class of grouping
  GROUPING_PREFIX_TYPE = "grouping-prefix-type", // prefix for -question, -text or -author
  GROUPING_DATA_NAME = "grouping-data-name", // data attribute conveying author provided group name
  GROUPING_DATA_LEVEL = "grouping-data-level", // grouping level, 1-based
  ITEM_PREFIX_AUTHORCLASS = "item-prefix-authorclass", // prefix for author class set with cssclasses on the metadata
  QUESTION_CLASS = "question-class", //: "bb-questionlabelgroup",
  QUESTION_PREFIX_TYPE = "question-prefix-type", //: "bb-itype",
  QUESTION_PREFIX_PROPERTY = "question-prefix-property", //: "bb-itype",
  QUESTION_DATA_LAYOUT = "question-data-layout", //: "data-form-group-layout",
  QUESTION_PREFIX_AUTHORSTYLE = "question-prefix-authorstyle", //: "bb-g-",
  QUESTION_CLASS_NOLABEL = "question-class-nolabel"; //: "form-group__question--no-label",

const nameDefaults = {
  [GROUPING_CLASS]: "form-group",
  [GROUPING_PREFIX_TYPE]: "form-group-",
  [GROUPING_DATA_NAME]: "data-form-group-name",
  [GROUPING_DATA_LEVEL]: "data-form-group-level",
  [ITEM_PREFIX_AUTHORCLASS]: "form-group__item--author-class-",
  [QUESTION_CLASS]: "bb-questionlabelgroup",
  [QUESTION_PREFIX_TYPE]: "bb-itype",
  [QUESTION_PREFIX_PROPERTY]: "question-", //: "bb-itype",
  [QUESTION_DATA_LAYOUT]: "data-form-group-layout",
  [QUESTION_PREFIX_AUTHORSTYLE]: "bb-g-",
  [QUESTION_CLASS_NOLABEL]: "form-group__question--no-label"
};

// const fg = {
//   [GROUPING_CLASS]: "no-form-group-outer",
//   [GROUPING_PREFIX_TYPE]: "form-group-",
//   [QUESTION_CLASS]: "bb-questionlabelgroup p-form-group",
//   [QUESTION_PREFIX_TYPE]: "bb-itype-",
//   [QUESTION_DATA_LAYOUT]: "data-p-form-group-layout-type",
//   [QUESTION_CLASS_NOLABEL]: "p-form-group-orphaned"
// };

// const bem_example = {
//   [GROUPING_CLASS]: "form-group",
//   [GROUPING_PREFIX_TYPE]: "form-group-",
//   [ITEM_PREFIX_AUTHORCLASS]: "form-group__item--author-class-",
//   [QUESTION_CLASS]: "bb-questionlabelgroup",
//   [QUESTION_PREFIX_TYPE]: "form-group__question-",
//   [QUESTION_DATA_LAYOUT]: "data-form-group-layout",
//   [QUESTION_PREFIX_AUTHORSTYLE]: "form-group__question--author-style-"
// };

const pathToNames = path(["arbitrary", "core", "form-group", "names"]);

export const names = propArg =>
  either(compose(prop(propArg), pathToNames), _ => prop(propArg, nameDefaults))(
    conf
  );
