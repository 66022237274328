/*

  'Store' module can be used by any plugin
  which requires state retention that
  can not be derived from the data in the
  current data in any given JSON res.

  Import the Map() 'state' and use the state
  to record data as the user progresses
  through the model. The state map is recorded
  in the session data 'uistates' every time it changes.

  If the user refreshes the page, or opens
  the session on a new tab/browser or at another
  time, the store module will make sure that
  state is updated with the recorded one - and
  an event wil be triggered 'bb:stateChange'

  NB: The state is shared over all other
  plugins which require it, so please namespace
  your entries.

  NB: The state is stringified to be stored
  as JSON - so don't get too fancy with your
  properties or values.

  Author: Tim Bauwens 2021

*/

import "proxy-polyfill/proxy.min.js";
import { bb } from "$json";
import { has } from "$json/lib/functional";

var innerState, state;

const store = {
  sessionid: "",
  fresh: true,
  pushing: false,
  push: function () {
    if (!this.fresh && !this.pushing) {
      this.pushing = true;
      // Wait for the innserState to finish setting.
      window.setTimeout(() => {
        var form = new URLSearchParams();
        form.set("fmt", "json");
        form.set("dbname", bb.getVar("dbname"));
        form.set("uniqueid", bb.getVar("uniqueid"));
        form.set("sessionid", bb.getVar("sessionid"));
        form.set("uistates", JSON.stringify(Object.fromEntries(state)));

        form.get("uniqueid") &&
          fetch("uistates", {
            method: "post",
            headers: {
              "Accept": "application/json, text/plain, */*",
              "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
            },
            body: form
          });
        store.pushing = false;
      }, 100);
    }
  }
};

$(document).on("bb:preHandleData", (e, data) => {
  if (data && data.sessionid) {
    if (store.sessionid !== data.sessionid) {
      store.fresh = true;
      store.sessionid = data.sessionid;
      state = null;
    }

    if (store.fresh) {
      // Page refreshed, or case opened.

      if (data && has("uistates")(data)) {
        innerState = data.uistates
          ? new Map(Object.entries(JSON.parse(data.uistates)))
          : new Map();
        state = new Proxy(innerState, {
          get(target, prop, receiver) {
            var value = Reflect.get(target, prop, receiver);
            if (typeof value === "function") {
              const origSet = value;
              value = function (key, value) {
                if (prop === "set" || prop === "clear") {
                  store.push();
                }
                return origSet.apply(innerState, arguments);
              };
            }
            return value;
          }
        });
        $(document).trigger("bb:stateChange");
      } else {
        innerState = new Map();
      }
      store.fresh = false;
    }
  }
});

export { state };
