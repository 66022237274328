/******* Translations ******/

const dicts = {};

let dict = {};

// gettext stub
function _(s, fb) {
  return dict[s] || fb || s;
}

_.tagged = (strings, ...values) =>
  strings.reduce((acc, cur, idx) => acc + cur + _(values[idx] || ""), "");

_.taggedWithin =
  prefix =>
  (strings, ...values) =>
    strings.reduce(
      (acc, cur, idx) =>
        acc +
        cur +
        (_(prefix, { [values[idx]]: values[idx] })[values[idx]] ||
          values[idx] ||
          ""),
      ""
    );

_.set = function (ob) {
  const lang = ob["lang"] || _("lang"); // Either provided or current lang?
  if (!dicts[lang]) {
    dicts[lang] = {};
  }
  for (let [key, val] of Object.entries(ob)) {
    // Set only if not set before (by _.set, or by _.addTranslations)
    dicts[lang][key] || (dicts[lang][key] = val);
  }
  dict = dicts[lang];
};

_.addTranslations = function (translations) {
  for (var term in translations) {
    if (translations.hasOwnProperty(term))
      for (var lang in translations[term]) {
        if (!dicts[lang]) dicts[lang] = {};
        // Always override what has been set before
        dicts[lang][term] = translations[term][lang];
      }
  }
};

Object.freeze(_);

export { _ };
