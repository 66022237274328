/* global $ */
import { quotable } from "./quotable.js";
import {
  apply,
  assoc,
  cond,
  compose,
  dec,
  equals,
  identity,
  lensPath,
  mergeLeft,
  match,
  over,
  path,
  pipe,
  tail,
  test,
  T
} from "./functional.js";
import { _ } from "./gettext.js";
import { format, formatPlus } from "./text-utils.js";

/*** DATE UTILITIES BEGIN ***/
Date.prototype.toHoursAndMinutes = function () {
  var hours = this.getHours();
  var minutes = this.getMinutes();
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  return hours + ":" + minutes;
};
const dateTimeRe = /^\/Date\((-?\d+)\)\/$/,
  pureDateRe = /^(\d{4})-(\d{2})-(\d{2})$/,
  ISO8601DateTimeInZuluRe =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2}(\.\d{3})?)?Z$/;

/*
 * Convert JSON date string to a Date object and return it.
 *
 * @{datestring}:
 *
 * An ISO-8601 datetime string in Zulu Time
 *
 * OR
 *
 * a JSON date string of the form
 *
 * "/Date(102938293293)/"
 *
 * where the numerical part is
 * seconds from epoch
 *
 * OR:
 *
 * a date formatted as "yyyy-mm-dd"
 *
 * OR:
 *
 * undefined -> undefined
 *
 */
const date = (...args) => new Date(...args);

export const parseDate = cond([
  [equals(0), () => undefined],
  [equals(undefined), identity],
  [equals(""), identity],
  [
    test(pureDateRe),
    // Note if we were to pass pureDates to new Date as a valid
    // DateStamp, the engine will interpret it as 0:00 Zulu
    // time. Which, in this very case, is probably not what we
    // want. We want the date as in the minds of the case (min/max in
    // a calendar etc). It should be represented 'as is' to the user,
    // so 2022-02-09 may never become 2022-02-08 in users TZ, so
    // interpret it according in users TZ.
    pipe(match(pureDateRe), tail, over(lensPath([1]), dec), apply(date))
  ],
  [test(ISO8601DateTimeInZuluRe), date],
  [test(dateTimeRe), pipe(match(dateTimeRe), path([1]), parseInt, date)],
  [
    T,
    thing => {
      throw `Not a date in our book: ${thing}`;
    }
  ]
]);

export const leadWithZeroes = string =>
  string.replace(/([^0-9]|^)([0-9]{3})([^0-9]|$)/, "$10$2$3");

/**
 *
 * @param {Object} spec Object describing a control conforming to the BB json api.
 * @param {String} value The value to be checked
 * @returns {Boolean|Error} true when value is according to spec
 * @throws Localized error message
 */
export function checkDate(spec = { notnull: false }, value) {
  const UIFormat = $.datepicker._defaults.dateFormat;
  const formatDate = $.datepicker.formatDate.bind($.datepicker);
  var date, mindate, maxdate;
  if (!spec.notnull && value.trim() === "") {
    return true;
  }
  const metadata = spec.metadata || {};
  try {
    date = valueToDate(UIFormat, value); // $.datepicker.parseDate(UIFormat, value); // This line may throw an error.
  } catch (e) {
    //couldn't parse - throw a translatable error message
    throw formatPlus(
      metadata.errdateinvalid || _("Invalid date"),
      compose(assoc("value", value), quotable)(spec)
    );
  }
  if (date === null) {
    if (spec.notnull) {
      throw format(
        metadata.errrequired || _("Date required"),
        compose(assoc("value", value), quotable)(spec)
      );
    }
    return true;
  }
  (mindate = parseDate(spec.minimum)), (maxdate = parseDate(spec.maximum));
  if (!(mindate || maxdate)) return true; // Neither is set
  const fmindate = formatDate(UIFormat, mindate),
    fmaxdate = formatDate(UIFormat, maxdate);
  const quotableDate = compose(
    mergeLeft({
      value,
      minimum: fmindate,
      maximum: fmaxdate
    }),
    quotable
  )(spec);
  try {
    if (mindate && !maxdate && date < mindate)
      throw (
        metadata.errdatebeforemimimum ||
        _("A date before {minimum} is not allowed")
      );
    else if (mindate && maxdate && (date < mindate || maxdate < date))
      throw (
        metadata.errdatenotinrange ||
        _("Date has to lie between {minimum} and {maximum}")
      );
    else if (maxdate && !mindate && maxdate < date)
      throw (
        metadata.errdateaftermaximum ||
        _("A date after {maximum} is not allowed")
      );
  } catch (e) {
    throw format(e, quotableDate);
  }
  return true;
}

export const valueToDate = (format, value) => {
  let date;
  try {
    date = $.datepicker.parseDate(format, value);
  } catch (e) {
    const yyyycleanformat = format.replace(/[^mdy]/g, "").replace("yy", "yyyy"),
      clean = value.replace(/[^0-9]/g, "");
    date = new Date();
    const yearindex = yyyycleanformat.indexOf("yyyy");
    // If year was less than 4 digits, and not at end, adjust indices of other fields.
    const adjustment = yearindex === 0 ? clean.length - 8 : 0;
    let year = Number(clean.substr(yearindex, 4 + adjustment));
    if (year <= 99) {
      const cutoffyear = Number.isInteger(
        $.datepicker._defaults.shortYearCutoff
      )
        ? $.datepicker._defaults.shortYearCutoff
        : (date.getYear() % 100) +
          Number($.datepicker._defaults.shortYearCutoff);
      // Note: this will become odd if current year approaches end of
      // century. Let's say we live in 2099. User enters 98 -> fine.
      // Is below 109. User enters 02 => not fine. Still below 109.
      // But jQuery UI should also fix this. And it's still a long time.
      const century = (date.getFullYear() / 100) >> 0;
      if (year <= cutoffyear) {
        year += century * 100;
      } else {
        year += (century - 1) * 100;
      }
    }
    const month = Math.max(
      0,
      Number(clean.substr(yyyycleanformat.indexOf("mm") + adjustment, 2)) - 1
    );
    const dom = Number(
      clean.substr(yyyycleanformat.indexOf("dd") + adjustment, 2)
    );
    date.setFullYear(year);
    date.setMonth(month);
    date.setDate(dom);
    if (
      date.getFullYear() !== year ||
      date.getMonth() !== month ||
      date.getDate() !== dom
    ) {
      throw "invalid date";
    }
  }
  return date;
};

export const onChangeDate = ev => {
  let date;
  try {
    date = valueToDate($.datepicker._defaults.dateFormat, ev.target.value);
  } catch (e) {
    // ignore
  } finally {
    if (date) {
      ev.target.value = leadWithZeroes(
        $.datepicker.formatDate($.datepicker._defaults.dateFormat, date)
      );
    }
  }
};

export const humanDate = (function () {
  var now = new Date();
  var hour0 = now.setHours(0, 0, 0, 0);
  var dayinms = 1000 * 60 * 60 * 24;
  var hour24 = hour0 + dayinms;
  var thisyear = now.getYear();
  var daysofweek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
    function (day) {
      return _(day);
    }
  );
  var monthsofyear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ].map(function (month) {
    return _(month);
  });
  function isToday(ms) {
    return ms > hour0 && ms < hour24;
  }
  function isYesterday(ms) {
    return hour0 > ms && ms > hour0 - dayinms;
  }
  function isLastWeek(ms) {
    return ms > hour0 - dayinms * 6;
  }
  function isThisYear(date) {
    return thisyear == date.getYear();
  }
  function humanDate(date) {
    if (!(date instanceof Date)) throw "Expects a Date object";
    var ms = date.valueOf();
    var hm = "";
    hm = date.toHoursAndMinutes();
    if (isToday(ms)) {
      return hm;
    }
    if (isYesterday(ms)) {
      return _("Yesterday") + ", " + hm;
    }
    if (isLastWeek(ms)) {
      return daysofweek[date.getDay()] + " " + hm;
    }
    return (
      date.getDate() +
      " " +
      monthsofyear[date.getMonth()] +
      (isThisYear(date) ? "" : " " + date.getFullYear()) +
      ", " +
      hm
    );
  }
  return humanDate;
})();
