import { userinfo } from "./user-info.js";
import {
  compose,
  either,
  includes,
  prop,
  strictDifference
} from "./functional.js";

export const PERM_ACCESSALLMODELS = "accessallmodels",
  // Allow to access all models
  PERM_ALLOWACCESSCASES = "allowaccesscases",
  // Allow to access to all the cases on the server
  PERM_ALLOWACCESSCASESFROMDOMAIN = "allowaccesscasesfromdomain",
  // Allow to access all the cases in the same domain on the server
  PERM_ALLOWALLCASEOVERVIEWDOMAIN = "allowallcaseoverviewdomain",
  // Allow to see all the cases in same domain on the server
  PERM_ALLOWALLCASESOVERVIEW = "allowallcasesoverview",
  // Allow to see all the cases made on the server
  PERM_ALLOWCASEOVERVIEW = "allowcaseoverview",
  // Allow to see the cases made by the user on the server
  PERM_ALLOWDOWNLOADCASE = "allowdownloadcase",
  // Allow user to download a case from the server
  PERM_ALLOWIPLOGTOUSER = "allowiplogtouser",
  // Allow the IP log to be send to the user
  PERM_ALLOWLOGTOUSER = "allowlogtouser",
  // Allow the WHOLE log of the server to be send to the user

  PERM_ALLOWMISALL = "allowmisall",
  // Allow all the MIS data to be shown
  PERM_ALLOWMISDOCUMENTS = "allowmisdocuments",
  // Allow the documents of the case to be opened
  PERM_ALLOWMISDOMAIN = "allowmisdomain",
  // Allow the MIS data within the same domain to be shown
  PERM_ALLOWMISOWNCASES = "allowmisowncases",
  // Allow the MIS data of the own cases to be shown

  PERM_ALLOWSAVECASE = "allowsavecase",
  // Allow to save a case on the server
  PERM_ALLOWSESSIONLOGTOUSER = "allowsessionlogtouser",
  // Allow the log concerning a case to be send to the user

  PERM_ALLOWSTARTCASES = "allowstartcases",
  // Allow to create a new case on the server
  PERM_PUBLISHMODEL = "publishmodel",
  // Allow to publish models to the server
  PERM_SAASCLIENTLOGICWRITABLE = "saasclientlogicwritable",
  // Allow users to change texts and logic in SAAS	Needs either updatemodels or updateownmodel to have effect
  PERM_SAASCLIENTWRITABLE = "saasclientwritable",
  // Allow users to change texts of the model in SAAS	Needs either updatemodels or updateownmodel to have effect
  PERM_SAASMANAGEPUBLISHINGSERVERS = "saasmanagepublishingservers",
  // Allow management of the publishing servers in server
  PERM_UPDATEDOMAINS = "updatedomains",
  // Allow to update or delete all the domains on the server (needs update users and models)
  PERM_UPDATEMODELS = "updatemodels",
  // Allow to update or delete all the models on the server
  PERM_UPDATEOWNMODEL = "updateownmodel",
  // Allow update or delete the models owned by user
  PERM_UPDATEOWNUSER = "updateownuser",
  // Allow to update the own user on the server, need this for webadmin access
  PERM_UPDATEROLES = "updateroles",
  // Allow to update or delete all the roles on the server
  PERM_UPDATESETTINGS = "updatesettings",
  // Allow to update the settings of the server
  PERM_UPDATEUSERS = "updateusers",
  // Allow to update or delete all the users on the server
  PERM_GETDATASOURCELIST = "getdatasourcelist",
  // Allow retrieving a sparse list of datasources
  PERM_MANAGEDATASOURCES = "managedatasources";
// Allow retrieving, posting, updating, and deleting datasource specs and file

// 4.12 equivalents
const oldperms = {
  guest: [PERM_ALLOWSTARTCASES],
  user: [
    PERM_PUBLISHMODEL,
    PERM_UPDATEOWNMODEL,
    PERM_UPDATEOWNUSER,
    PERM_ALLOWCASEOVERVIEW,
    PERM_ALLOWSTARTCASES,
    PERM_ALLOWSAVECASE,
    PERM_ALLOWMISOWNCASES // ,
    // PERM_SAASCLIENTWRITABLE,
    // PERM_SAASCLIENTLOGICWRITABLE
  ],
  administrator: [
    PERM_PUBLISHMODEL,
    PERM_ACCESSALLMODELS,
    PERM_UPDATEOWNMODEL,
    PERM_UPDATEDOMAINS,
    PERM_UPDATESETTINGS,
    PERM_UPDATEMODELS,
    PERM_UPDATEUSERS,
    // PERM_UPDATEROLES,
    PERM_UPDATEOWNUSER,
    PERM_ALLOWCASEOVERVIEW,
    PERM_ALLOWACCESSCASESFROMDOMAIN,
    PERM_ALLOWACCESSCASES,
    PERM_ALLOWSTARTCASES,
    PERM_ALLOWSAVECASE,
    PERM_ALLOWMISOWNCASES,
    PERM_ALLOWMISDOCUMENTS,
    // PERM_SAASCLIENTWRITABLE,
    // PERM_SAASCLIENTLOGICWRITABLE,
    // PERM_SAASMANAGEPUBLISHINGSERVERS,
    // PERM_SAASCLIENTWRITABLE,
    PERM_ALLOWDOWNLOADCASE // ,
    // PERM_MANAGEDATASOURCES
  ],
  viewer: [PERM_ALLOWMISDOMAIN],
  manager: [
    PERM_UPDATEOWNUSER,
    PERM_ALLOWCASEOVERVIEW,
    PERM_ALLOWACCESSCASESFROMDOMAIN,
    PERM_ALLOWACCESSCASES,
    PERM_ALLOWSAVECASE,
    PERM_ALLOWMISOWNCASES // ,
    // PERM_SAASCLIENTWRITABLE
  ],
  vieweradmin: [PERM_ALLOWMISALL, PERM_ALLOWMISDOMAIN, PERM_ALLOWMISDOCUMENTS],
  any: []
};

export const permissions = () =>
  compose(
    either(
      prop("permissions"),
      compose(role => oldperms[role] || oldperms["any"], prop("role"))
    ),
    userinfo
  )(null);

export const hasPermission = perm =>
  perm instanceof Array
    ? strictDifference(perm, permissions()).length === 0
    : includes(perm, permissions());

export const webadminMakesSense = permissions =>
  permissions.some(perm => perm.startsWith("update"));
