import { converter } from "$md-converter";
import { conf } from "$json/lib/conf";
import { updateLabels } from "$json/lib/feature-queries";
import { isLabel } from "$json/lib/control-helpers";
import { has } from "$json/lib/functional";

/* md-labels.js:
 * Markdown all labels after insertion into the DOM
 *
 *
 *
 * Author: Niels Giesen
 * Copyright 2013, 2015, 2019 Berkeley Bridge
 *
 */

(function ($) {
  $(function () {
    var topLevel, policy;

    try {
      var baseLevel = conf.arbitrary.headings.baseLevel;
      if (baseLevel === undefined) throw "baseLevel is not defined";
      topLevel = baseLevel - 1;
    } catch (err) {
      topLevel = 0;
    }

    try {
      policy = conf.arbitrary.showdown.policy;
      if (["legacy"].indexOf(policy) === -1)
        throw "showdown.policy is not 'legacy'";
    } catch (err) {}

    const newline = conf.plugins.includes("showdown") ? "\n\n" : "\n";
    const newlineGlobRe = new RegExp(newline, "g");

    /**
     * Regular expression for paragraph open/end tags.
     * We don't want them.
     *
     **/
    var br_re = /<br *\/?>/gi,
      onlyPhrasingInside = [
        "P",
        "H1",
        "H2",
        "H3",
        "H4",
        "H5",
        "H6",
        "SPAN",
        "LABEL",
        "LEGEND",
        "CAPTION"
      ];

    if (policy === "legacy") {
      onlyPhrasingInside.splice(9);
    }

    $.fn.showdown = function () {
      return this.each(function (i, e) {
        if (e.classList.contains("bb-p-showdown-labels")) return;
        e = $(e);
        var html_in, html_out;
        var onlyPhrasing = allowOnlyPhrasing(e);
        let newline = "\n";

        html_in = e.html().replace(br_re, newline);
        html_out = converter.makeHtml(html_in, onlyPhrasing);
        if (onlyPhrasing)
          html_out = html_out.trim().replace(newlineGlobRe, "<br/>");

        if (topLevel !== 0)
          html_out = html_out.replace(/(<\/?h)([1-6])/g, demoteHeading);

        e.html(html_out).addClass("bb-p-showdown-labels");
      });
    };

    function allowOnlyPhrasing(e) {
      return (
        onlyPhrasingInside.indexOf(e.get(0).nodeName) > -1 ||
        (policy !== "legacy" && e.hasClass("bb-label"))
      );
    }

    function demoteHeading(m, m1, m2) {
      return m1 + Math.min(6, topLevel + Number(m2));
    }

    $(document).on("bb:postHandleData", function (event, data) {
      if (data && data.groups) {
        $(".bb-md-able").showdown();
        $(document).trigger("bb:md-done");
        $(document).trigger("bb:resized");
      }
    });
  });

  document.addEventListener(
    "bb:updatedControl",
    ({ detail: { control, Updates } }) => {
      if (has("value", Updates) && isLabel(control) && updateLabels(conf)) {
        control._elt.classList.remove("bb-p-showdown-labels");
        control.$elt.showdown();
      }
      if (
        has("value", Updates) &&
        control.controltype === "grid" &&
        updateLabels(conf)
      ) {
        control.$elt.find(".bb-md-able").showdown();
      }
    }
  );
})(jQuery);
