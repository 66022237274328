/* global $ FormData URLSearchParams */
let _vars = {};

const SESSION_KEYS = ["dbname", "sessionid", "uniqueid"];
const NAV_KEYS = ["screenid", ...SESSION_KEYS];

Object.freeze(SESSION_KEYS);
Object.freeze(NAV_KEYS);

function setValueInVars(data, key) {
  if (typeof data[key] != "undefined") _vars[key] = data[key];
}

export function setVars(data) {
  setValueInVars(data, "version");
  setValueInVars(data, "uniqueid");
  setValueInVars(data, "replyserveraddress");
  setValueInVars(data, "replyserverport");
  setValueInVars(data, "proxyredirect");
  setValueInVars(data, "sessionid");
  setValueInVars(data, "modelname");
  setValueInVars(data, "dbname");
  setValueInVars(data, "modelid");
  setValueInVars(data, "showdeleteinmenu");
  setValueInVars(data, "showdatecreated");
  setValueInVars(data, "showreport");
  setValueInVars(data, "userinfo");
  setValueInVars(data, "showcopycase");
  setValueInVars(data, "screenid");
  var version = getVar("version");
  if (typeof version === "string") _vars["version"] = version.split(".");
}

export function getVar(string, obj) {
  return $.extend({}, _vars, obj)[string];
}

/**
 * Unset either all vars, or only those present in param vars
 *
 * @param {Array} vars An array of variable keys to delete
 *
 **/
function unsetVars(arr) {
  if (!arr) return (_vars = {});
  arr.forEach(function (key) {
    delete _vars[key];
  });
  return _vars;
}

function collect(keys, collector = new FormData(), obj) {
  function append(key) {
    const val = getVar(key, obj);
    if (val !== undefined) collector.append(key, getVar(key, obj));
  }
  if (Array.isArray(keys)) {
    keys.forEach(append);
  } else {
    append(keys);
  }
  return collector;
}

function querify(keys, obj) {
  const usp = collect(keys, new URLSearchParams(), obj);
  return usp.toString();
}

export default {
  querify,
  setVars,
  getVar,
  unsetVars,
  collect,
  NAV_KEYS,
  SESSION_KEYS
};
