/*
  metadata-scoring.js
  A plugin to add scoring data.
*/

import { state } from "$json/lib/store";
import { getmetadata } from "$json/lib/getmetadata";

var scoreMap = new Map();

const getAverage = scoresobj => {
  let scores = Object.values(scoresobj);
  if (scores.length) {
    let total = scores.reduce(
      (accumulator, currentValue) => accumulator + currentValue
    );
    return total / scores.length;
  } else {
    return 0;
  }
};

class Chapter {
  constructor() {
    this.scores = {};
    this.average = 0;
  }
}

((doc, $) => {
  const applystate = () => {
    // Run through the jumplist(s) (incl. fake ones) and add scores.
    let jumplist = doc.querySelectorAll(".bb-jumplist li, .faux-jumplist li");
    jumplist.forEach(li => {
      let btn = li.querySelector("button");
      if (btn !== null) {
        let groupid = btn.getAttribute("data-groupid");
        if (groupid !== null) {
          if (groupid.includes(".")) {
            // This is a chapter.
            if (scoreMap.has(groupid)) {
              btn.setAttribute(
                "data-average-score",
                scoreMap.get(groupid).average
              );
              if (scoreMap.get(groupid).hasOwnProperty("final"))
                btn.setAttribute(
                  "data-final-score",
                  scoreMap.get(groupid).final
                );
            }
          } else {
            btn.setAttribute("data-score", scoreMap.get(groupid));
          }
        }
      }
    });
    // Trigger an event for other plugins to react to score changes.
    $(doc).trigger("bb:scoresUpdated", [scoreMap]);
  };

  $(doc).on("bb:stateChange", _e => {
    let statescores = state.has("scoreMap")
      ? new Map(Object.entries(state.get("scoreMap")))
      : new Map();
    scoreMap = new Map(statescores);
    applystate();
  });

  $(doc).on("bb:postHandleData bb:hasUpdated", (_e, data) => {
    if (data && data.groups && data.groups.length) {
      let md = getmetadata(data).props;
      let group = data.groups[data.groups.length - 1];
      let groupid = group.groupid;
      let chapterid = group.chapterid;

      // Grab scores from md and add to scoreMap.
      if (md.has("score") && md.get("score") !== "initial") {
        scoreMap.set(groupid, Number(md.get("score")));
        // Also maintain chapter average scores.
        if (chapterid !== "") {
          let chapterobj = scoreMap.has(chapterid)
            ? scoreMap.get(chapterid)
            : new Chapter();

          chapterobj.scores[groupid] = Number(md.get("score"));
          chapterobj.average = getAverage(chapterobj.scores);
          scoreMap.set(chapterid, chapterobj);
        }
      }

      // Also grab chapter scores if the model wishes to provide them.
      if (md.has("chapterscore")) {
        // In case the modeler provides a chapter score from outside the chapter, ignore it.
        if (chapterid !== "") {
          let chapterobj = scoreMap.has(chapterid)
            ? scoreMap.get(chapterid)
            : new Chapter();

          chapterobj.final = Number(md.get("chapterscore"));
          scoreMap.set(chapterid, chapterobj);
        }
      }
      if (scoreMap.size) {
        state.set("scoreMap", Object.fromEntries(scoreMap));
        applystate();
      }
    }
  });
})(document, jQuery);
