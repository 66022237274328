// custom-radios.js

/*
 * A simple plugin to replace
 * radios with a custom design.
 * Cross-browser and working with all
 * forms of output: grids and radios
 *
 * Note you'll have to provide your own styling to the faux .p-custom-radios-label
 *
 * Author: Niels Giesen
 * Copyright Berkeley Bridge 2019
 *
 */

((doc, $) => {
  $(doc).on("bb:postHandleData", (e, data) => {
    // If there are any checkboxes, deal with them
    let checkboxes = $("#bb-q").find(
      'input[type="radio"]:not(.p-custom-radios)'
    );
    checkboxes.each((i, checkbox) => {
      checkbox.className += " a-offscreen p-custom-radios";
      checkbox.insertAdjacentHTML(
        "afterEnd",
        `
        <label
          class="p-custom-radios-label"
          for="${checkbox.id}"
        ></label>
       `
      );
    });
  });
})(document, jQuery);
