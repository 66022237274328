/* global $ */
import {
  all,
  both,
  curry,
  filter,
  has,
  compose,
  ifElse,
  head,
  path,
  prop,
  propEq,
  any,
  find,
  pathOr,
  split,
  join,
  map,
  when,
  tap,
  cond,
  not
} from "./functional.js";
import {
  isTextual,
  isPicture,
  isQuestion,
  isVisible
} from "./control-helpers.js";
import { groupOuter, baseGroup, tailGroup, pathGroup } from "./groupings";
import { normalize } from "./font-classes";
import { getWidget } from "./form-widgets.js";
import { conf } from "./conf";
import * as n from "./names.js";
import { names } from "./names.js";

const doTopLevel = pathOr(true, [
  "arbitrary",
  "core",
  "form-group",
  "toplevel"
]);

const doLegends = pathOr(true, [
  "arbitrary",
  "core",
  "form-group",
  "doLegends"
]);

const getLayout = type =>
  pathOr(compose(prop("layout"), getWidget)(type), [
    "arbitrary",
    "form-group",
    type
  ]);

const getGroupName = compose(when(Boolean, baseGroup), pathGroup, head);

// Interface Font Style
const questionGroupClass = compose(
  s => names(n.QUESTION_PREFIX_AUTHORSTYLE) + s,
  normalize,
  prop("font-class")
);

// interface metadata: cssclasses=
export const extraClasses = prefix =>
  compose(
    when(
      Boolean,
      compose(
        join(" "),
        map(compose(s => names(prefix) + s, normalize)),
        split(" ")
      )
    ),
    path(["metadata", "cssclasses"])
  );

const asciify = s => s.replace(/[^a-z-]/g, "-");

const areAllInvisible = compose(not, any(isVisible));
const areAllReadonly = both(
  any(both(isQuestion, isVisible)),
  compose(all(prop("originalreadonly")), filter(both(isQuestion, isVisible)))
);

const areAllNotNull = both(
  any(both(isQuestion, isVisible)),
  compose(all(prop("notnull")), filter(both(isQuestion, isVisible)))
);

const updateClassWhen = curry((className, fn, controls, elt) => {
  elt.classList.toggle(className, fn(controls));
});

const setReadonlyFGClass = updateClassWhen(
  names(n.GROUPING_PREFIX_TYPE) + "-readonly",
  areAllReadonly
);
const setEmptyFGClass = updateClassWhen(
  names(n.GROUPING_PREFIX_TYPE) + "-empty",
  areAllInvisible
);
const setRequiredFGClass = updateClassWhen(
  names(n.GROUPING_PREFIX_TYPE) + "-required",
  areAllNotNull
);

const setReadonlyQClass = updateClassWhen(
  names(n.QUESTION_PREFIX_PROPERTY) + "-readonly",
  areAllReadonly
);
const setEmptyQClass = updateClassWhen(
  names(n.QUESTION_PREFIX_PROPERTY) + "-empty",
  areAllInvisible
);
const setRequiredQClass = updateClassWhen(
  names(n.QUESTION_PREFIX_PROPERTY) + "-required",
  areAllNotNull
);

export const createFormGroup = (wControl, group, level) => c => {
  let formGroup;
  const groupName = getGroupName(c);

  if (
    groupName ||
    (doTopLevel(conf) &&
      (doLegends(conf) || !all(propEq("controltype", "legend"))(c)))
  ) {
    formGroup = document.createElement("div");
    formGroup.className = names(n.GROUPING_CLASS);
    formGroup.setAttribute(names(n.GROUPING_DATA_LEVEL), level);
    setEmptyFGClass(c, formGroup);
    setReadonlyFGClass(c, formGroup);
    setRequiredFGClass(c, formGroup);
    formGroup.addEventListener("bb:updatedControl", ({ detail }) => {
      if (has("visible", detail.Updates)) {
        setEmptyFGClass(c, formGroup);
      }
      if (has("readonly", detail.Updates)) {
        setReadonlyFGClass(c, formGroup);
      }
      if (has("notnull", detail.Updates)) {
        setRequiredFGClass(c, formGroup);
      }
    });
    if (groupName) {
      formGroup.setAttribute(names(n.GROUPING_DATA_NAME), asciify(groupName));
      formGroup.classList.add(names(n.GROUPING_PREFIX_TYPE) + "-author");
    } else if (find(isQuestion, c)) {
      formGroup.classList.add(names(n.GROUPING_PREFIX_TYPE) + "-interface");
    } else if (compose(isPicture, head)(c)) {
      formGroup.classList.add(names(n.GROUPING_PREFIX_TYPE) + "-picture");
    } else if (compose(isTextual, head)(c)) {
      formGroup.classList.add(names(n.GROUPING_PREFIX_TYPE) + "-text");
    }
  } else {
    formGroup = document.createDocumentFragment();
  }

  const cWithin = compose(
    groupOuter,
    map(
      when(
        pathGroup,
        tap(control =>
          compose(
            ifElse(
              s => s === "",
              () => delete control.metadata.group,
              group => (control.metadata.group = group)
            ),
            tailGroup
          )(control.metadata.group)
        )
      )
    )
  )(c);
  cWithin.forEach(
    cond([
      [
        getGroupName,
        compose(
          n => formGroup.appendChild(n),
          createFormGroup(wControl, group, level + 1)
        )
      ],
      [
        any(isQuestion),
        controls => {
          const answer = find(isQuestion, controls);
          const classes = [
            names(n.QUESTION_CLASS),
            `${names(n.QUESTION_PREFIX_TYPE)}-${answer.controltype}`,
            questionGroupClass(answer),
            extraClasses(n.ITEM_PREFIX_AUTHORCLASS)(answer),
            controls.length === 1 && names(n.QUESTION_CLASS_NOLABEL)
          ].filter(Boolean);
          const qlg = document.createElement("div");
          qlg.setAttribute(
            names(n.QUESTION_DATA_LAYOUT),
            answer._layout || getLayout(answer.controltype)(conf)
          );
          qlg.className = classes.join(" ");
          setEmptyQClass(controls, qlg);
          setReadonlyQClass(controls, qlg);
          setRequiredQClass(controls, qlg);
          qlg.addEventListener("bb:updatedControl", ({ detail }) => {
            if (has("visible", detail.Updates)) {
              setEmptyQClass(controls, qlg);
            }
            if (has("readonly", detail.Updates)) {
              setReadonlyQClass(controls, qlg);
            }
            if (has("notnull", detail.Updates)) {
              setRequiredQClass(controls, qlg);
            }
          });
          controls.forEach(c => wControl(c, group, $(qlg)));
          formGroup.appendChild(qlg);
        }
      ],
      [
        () => true,
        map(c => {
          const $widget = wControl(c, group, $(formGroup));
          if ($widget)
            $widget.addClass(extraClasses(n.ITEM_PREFIX_AUTHORCLASS)(c));
        })
      ]
    ])
  );
  return formGroup;
};
