/*** CONFIG BEGIN ***/

import conf from "$conf.json";
import { has } from "./functional.js";

conf.a11y = Object.assign(
  {
    captions: false,
    optionfieldsets: false,
    strictlegends: false
  },
  conf.a11y
);

function propFinder(ob, prefix) {
  if (prefix) ob = find(prefix);

  function find(prop, fallback) {
    if (ob === undefined) return fallback;
    if (prop === undefined) return fallback;
    var leafs = prop.split(".").filter(Boolean),
      leaf = ob;
    while (has(leafs[0], leaf)) {
      leaf = leaf[leafs.shift()];
    }
    if (leafs.length === 0) return leaf;
    return fallback;
  }

  return find;
}

export { conf, propFinder };

/*** CONFIG END ***/
