/* global URLSearchParams */
import "./polyfills/url-search-params.js";
import { Mode } from "./mode.js";
import { serializeQuestions } from "./collect-values.js";
import Vars from "./vars.js";

export const shouldExit = () =>
  Mode.get("hasModel") && Boolean(Vars.getVar("sessionid"));

export const onEnd = () => {
  if (!shouldExit()) return;
  if (!navigator.sendBeacon) return;
  const data = [
    "step=exitdelayed",
    Vars.querify(Vars.NAV_KEYS),
    "fmt=json",
    serializeQuestions()
  ]
    .filter(Boolean)
    .join("&");

  const usp = new URLSearchParams(data);

  navigator.sendBeacon("/action", usp);
};

function addEndListener() {
  // Update state before navigating away, but do not logout
  if ("onpagehide" in window) {
    window.addEventListener("pagehide", onEnd);
  } else {
    window.addEventListener("unload", onEnd, false);
  }
}

export function removeEndListener() {
  // Update state before navigating away, but do not logout
  if ("onpagehide" in window) {
    window.removeEventListener("pagehide", onEnd);
  } else {
    window.removeEventListener("unload", onEnd, false);
  }
}

addEndListener();
