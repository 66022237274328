/* global $ */
import { getWidget } from "./form-widgets.js";
import {
  always,
  assoc,
  either,
  tap,
  cond,
  path,
  propEq,
  T
} from "./functional.js";
import { _ } from "./gettext.js";
import { format, formatPlus } from "./text-utils.js";
import { listtypes } from "./types.js";
import { compose } from "./functional.js";
import { quotable } from "./quotable.js";

// Validate actual input, return true if it's ok, throw an error otherwise.
export function validateInput(node) {
  const $node = $(node),
    control = $node.data("control");
  let ok = true,
    re,
    errortext,
    val,
    category;

  if (control === undefined) {
    throw "Not a BB widget";
  }

  if (control.visible === false) {
    return true;
  }
  category = listtypes[control.controltype];

  val = $node.val();

  if (control.stringmask) {
    re = new RegExp(control.stringmask);
    if (!re.test(val))
      throw format(
        control.errortext,
        compose(assoc("value", val), quotable)(control)
      );
  }
  if (control.notnull) {
    if (category === "LIST") {
      ok = $node.is(":has(:checked)");
    } else if (category === "BOX") {
      // There could be a faux, checked, option telling us to
      // fill in the field. That is the second check here.
      ok = val !== null && val !== "";
    } else if (control.controltype == "checkbox") {
      ok = $node.is(":checked");
    } else if (control.controltype === "grid") {
      if (control.addallowed === false) {
        const checkable = control.columns.find(
          either(
            propEq("controltype", "radiobutton"),
            propEq("controltype", "checkbox")
          )
        );
        if (checkable) {
          ok = $node.is(":has(:checked)");
        }
      } else {
        // A required grid should have at least one row.
        // But if no rows **can** be added it would be rude and confusing to complain to the end-user.
        ok = control.value.length > 0 || control.addallowed === false;
      }
    } else {
      re = /\S/;
      ok = re.test(val);
    }
    if (!ok) {
      throw cond([
        [path(["metadata", "errrequired"]), path(["metadata", "errrequired"])],
        [
          propEq("controltype", "datetimepicker"),
          compose(c => formatPlus(_("Date required"), c), quotable)
        ],
        [
          always(["BOX", "LIST"].indexOf(category) > -1),
          compose(c => format(_("Choice required"), c), quotable)
        ],
        [T, compose(c => format(_("Field required"), c), quotable)]
      ])(control);
    }
  }
  if (control.maxlength && control.maxlength > 0) {
    ok = val.length <= control.maxlength;
    if (!ok) {
      throw format(
        path(["metadata", "errtexttoolong"])(control) ||
          _("Text length exceeds the maximum of {maxlength} characters"),
        quotable(control)
      );
    }
  }
  if (getWidget(control.controltype).validate) {
    return getWidget(control.controltype).validate(control, val);
  }
  return true;
}
