/* global $ */
import { html, render } from "lit";
import { Maybe } from "@cahmoraes93/maybe";
import { A11y, observeDatepickers } from "./a11y.js";
import { conf, propFinder } from "./conf.js";
import { wControl, getControl } from "./control.js";
import { quotable } from "./quotable.js";
import {
  isLabel,
  isOption,
  isQuestion,
  isReadOnly,
  renderAttribs,
  setAttribs
} from "./control-helpers.js";
import { checkDate, onChangeDate, parseDate, leadWithZeroes } from "./dates.js";
import { escapeHTML } from "./escape.js";
import { registerWidget } from "./form-widgets.js";
import {
  allPass,
  always,
  applyTo,
  both,
  compose,
  chain,
  dec,
  dissoc,
  find,
  F,
  gt,
  head,
  has,
  ifElse,
  inc,
  join,
  map,
  mergeLeft,
  path,
  propEq,
  when,
  pathEq,
  reverse,
  pipe,
  lensPath,
  lensProp,
  over,
  append,
  assoc,
  infichain,
  toLower,
  omit,
  nth,
  prop,
  propOr,
  equals,
  tap
} from "./functional.js";

import { _ } from "./gettext.js";
import { fromApiServer } from "./location.js";
import { forceInRange, Numerals } from "./numerals.js";
import { format, formatPlus } from "./text-utils.js";
import { urlutils } from "./url-utils.js";

const arbitraryCoreProp = propFinder(conf, "arbitrary.core");

function disconnectValue({ input, value, filter = x => x }) {
  input.setAttribute("data-server-value", value);
  $(input).on("change", function () {
    try {
      var newVal = filter(input.value, input);
    } catch (e) {
      //
    } finally {
      if (typeof newVal !== "undefined") {
        input.setAttribute("data-server-value", newVal);
      }
    }
  });
}

const noAttribs = always({});

const omitName = dissoc("name");
const omitRequired = dissoc("aria-required");

const emptyTag = (tagName, attribs) => {
  const elt = document.createElement(tagName);
  setAttribs(elt, attribs);
  return elt;
};
const valueInside = (control, attribs, tagName) => {
  const elt = emptyTag(tagName, attribs);
  if (tagName === "textarea") {
    elt.value = control.value;
  } else {
    elt.innerHTML =
      control.text || escapeHTML(control.value).replace(/\r?\n/g, "<br/>");
  }
  return elt;
};

const checkbox = {
  name: "checkbox",
  render: (control, group, attribs) => {
    return emptyTag("input", attribs);
  },
  attribs: (attr, control, enabled) =>
    Object.assign(
      {},
      attr,
      {
        type: "checkbox"
      },
      //Interface already has got a value server-side, so not
      //sending it back poses no problem.
      control.value && { checked: true }
    ),
  setValue: (input, value, requestor) => {
    input.checked = value;
  },
  layout: "inline"
};

registerWidget(checkbox);

const picture = {
  name: "picture",
  render: (control, group, attribs) => emptyTag("img", attribs),
  attribs: (attr, control, enabled) =>
    Object.assign({}, attr, {
      alt: control.alttext,
      src: fromApiServer(control.filename)
    })
};

registerWidget(picture);

export const edit = {
  name: "edit",
  couldWrap: true,
  attribs: (attr, control, enabled) =>
    Object.assign({}, attr, {
      type: "text",
      autocomplete: "off",
      value: control.value
    }),
  render: (control, group, attribs) => emptyTag("input", attribs),
  setValue: (input, value) => {
    input.value = value;
  },
  layout: "aside"
};

registerWidget(edit);

const memo = {
  name: "memo",
  attribs: (attr, control, enabled) => {
    // const attribs = ["name", control.name];
    // if (control.width && control.height && control["font-size"]) {
    //   attribs.cols= parseInt(control.width / (control["font-size"] * 0.75))
    //   attribs.rows =  parseInt(control.height / (control["font-size"] * 1.5))
    // }
    // return [...attr, ...attribs];
    return attr;
  },
  render: (control, group, attribs) => {
    return valueInside(control, attribs, "textarea");
  },
  setValue: edit.setValue,
  layout: "below"
};

registerWidget(memo);

const label = {
  name: "label",
  fixup: control => {
    control.value = (control.value || "").replace(/\r\n/g, "\n"); // Normalize Windows-style (typewriter) newlines
    return control;
  },
  attribs: (attr, control, enabled) =>
    Object.assign(
      omitName(attr),
      enabled && control.isFor && { for: control.isFor },
      {
        class: `${
          control.isfor || has("colname", control) ? "bb-label" : "bb-text"
        } bb-md-able`
      }
    ),
  render: (control, group, attribs) => {
    return valueInside(
      control,
      attribs,
      control._subtype === "heading"
        ? "h" + Math.min(6, control._level || 2)
        : control.isFor
          ? "label"
          : "div"
    );
  },
  setValue: (input, value) => {
    input.innerHTML = escapeHTML(value).replace(/\r?\n/g, "<br/>");
  }
};

registerWidget(label);

export const linklabel = {
  name: "linklabel",
  fixup: control => {
    control.value = control.value.trim();
    // Fix 'empty' or JS-links
    if (urlutils.isEmptyish(control.url) || urlutils.isJS(control.url)) {
      if (urlutils.isJS(control.url)) control.value = urlutils.JSAlertMessage;
      delete control.url;
    }
    return control;
  },
  attribs: (attr, control, enabled) => {
    let linkClassName = "";

    if (control.url) {
      attr["href"] = fromApiServer(control.url);
      if (control.url.match(/mailto:/)) {
        attr["type"] = "email";
        linkClassName = "mail";
      } else {
        linkClassName = "bb-external";
        if (control.isreport) linkClassName += " report";
        var mimetype = control.mimetype || urlutils.mimetype(control.url);
        if (mimetype) {
          attr["type"] = mimetype.mimetype;
          linkClassName += " " + mimetype.ext;
        }
      }
      if (control.url[0] !== "#") attr["target"] = "_blank";
    }
    linkClassName += " bb-text";
    attr["class"] = linkClassName;
    return omitName(attr);
  },
  render: (control, group, attribs) => {
    return valueInside(control, attribs, "a");
  },
  setValue: (input, value) => {
    input.innerHTML = escapeHTML(value).replace(/\r?\n/g, "<br/>");
  },
  layout: "below"
};

registerWidget(linklabel);

const listlabel = {
  name: "listlabel",
  attribs: compose(
    omitName,
    omitRequired,
    mergeLeft({
      type: "listlabel",
      class: "listlabel"
    })
  ),
  item: item =>
    `<li class="bb-md-able">${escapeHTML(item).replace(/\\n/g, "<br/>")}</li>`,
  render: (control, group, attribs) => {
    const value = control.value;
    const items = compose(join(""), map(listlabel.item))(value);
    const widget = `<ul ${renderAttribs(attribs)}>
${items}</ul>`;
    return $(widget);
  },
  setValue: (input, value, requestor) => {
    input.innerHTML = compose(join(""), map(listlabel.item))(value);
    $.fn.showdown && $(input).find("li").showdown();
  },
  layout: "below"
};

registerWidget(listlabel);

const optionbox = {
  render: (control, group, attribs) => {
    const value = control.value;
    const widget = $(`<select ${renderAttribs(attribs)}></select>`);
    // Set head of drop-down list to an empty item.
    // If value[0].value is already the empty string, the server
    // has already done this for us (pre 4.7 or dataset-based
    // drop-down).
    // If one of the options has already been selected, do *not*
    // add this empty option.
    // @todo: remove this code once server release 4.7 or higher
    // runs everywhere.
    if (
      control.controltype === "combobox" &&
      value.length > 0 &&
      value[0].value !== "" &&
      !value.some(function (value) {
        return value.selected === true;
      })
    ) {
      value.unshift({ option: "", value: "" });
    }
    optionbox.setValue(widget.get(0), value);
    return widget;
  },
  setValue: (input, value) => {
    const options = [...input.querySelectorAll("option")];
    const len = options.length;
    const newLen = value.length;
    const newOptions = input.append ? [] : null;
    let selectedValue = "";
    for (let i = 0; i < newLen; i++) {
      const option = value[i];
      const optionElt = options[i] || document.createElement("option");
      optionElt.value = option.value;
      optionElt.textContent =
        value[i].value === "" ? _("Choose...") : option.option;
      optionElt.classList.toggle("bb-valueless", option.value === "");
      if (option.selected) {
        selectedValue = option.value;
      }
      if (i > len - 1) {
        if (newOptions) {
          newOptions.push(optionElt);
        } else {
          input.appendChild(optionElt);
        }
      }
    }
    if (newOptions.length) {
      input.append(...newOptions);
    } else if (newLen < len) {
      [...input.querySelectorAll(`option:nth-child(1n+${newLen + 1})`)].forEach(
        input => input.parentNode.removeChild(input)
      );
    }
    input.value = selectedValue;
  }
};

const combobox = {
  name: "combobox",
  // fixup: control => {
  //   if (control.freetext === true) {
  //     control.controltype = "edit";
  //     control.datalist = control.value.map(({ option }) => option);
  //     control.placeholder = _("Choose or type...");
  //     let val = control.value.find(v => v.selected);
  //     if (val) control.value = val.option;
  //     else control.value = "";
  //   }
  //   return control;
  // },
  fixup: control => {
    if (control.freetext === true) {
      control.controltype = "freebox";

      // control.datalist = control.value.map(({ option }) => option);
      // control.placeholder = _("Choose or type...");
      // let val = control.value.find(v => v.selected);
      // if (val) control.value = val.option;
      // else control.value = "";
    }
    return control;
  },
  couldWrap: true,
  attribs: (attr, control, enabled) =>
    Object.assign({}, attr, {
      name: control.name,
      size: 1
    }),
  render: optionbox.render,
  setValue: optionbox.setValue,
  layout: "below"
};

registerWidget(combobox);

const listbox = {
  name: "listbox",
  attribs: (attr, control, enabled) =>
    Object.assign({}, attr, {
      name: control.name,
      size: control.value.length
    }),
  render: optionbox.render,
  setValue: optionbox.setValue,
  layout: "below"
};

registerWidget(listbox);

const multilist = {
  name: "multilist",
  attribs: (attr, control, enabled) =>
    Object.assign({}, attr, {
      name: control.name,
      size: control.value.length,
      multiple: "multiple"
    }),
  render: optionbox.render,
  setValue: (input, value, requestor) => {
    const selected = value.find(option => option.selected === true);
    input.value = selected.value;
  },
  layout: "below"
};

registerWidget(multilist);

const optionlist = {
  render: (control, group, attribs) => {
    const value = control.value;
    if (value.length === 0) return null;
    const widget = $(`<ul ${renderAttribs(attribs)}></ul>`);
    for (let i = 0; i < value.length; i++) {
      let id = control.id + "-" + i;
      var option = $(`
        <li class="bb-option
          ${value[i].selected ? ' checked"' : '"'}>
          <label class="bb-option__label" for="${id}">
            <input
              type="${control.controltype == "radio" ? "radio" : "checkbox"}"
              name="${control.name}"
              id="${id}"
              ${
                control.aria && control.aria.labelledby
                  ? `aria-describedby="${control.aria.labelledby}"`
                  : ""
              }
              ${value[i].selected ? 'checked="checked" ' : ""}
            />
            <span class="bb-option__span bb-md-able">
              ${escapeHTML(value[i].option).replace(/\\n/g, "<br/>")}
            </span>
          </label>
        </li>
      `);
      widget.append(option);
      widget.find("input:last").val(value[i].value);
    }
    return widget;
  },
  onrequired: (input, required) => {
    // $(input).find("input").attr("aria-required", required);
  },
  onreadonly: (list, readonly) => {
    list.classList.toggle("bb-disabled", readonly);
    [...list.querySelectorAll("input")].forEach(input => {
      input.disabled = readonly;
    });
  }
};

const checklist = {
  name: "checkmultilist",
  attribs: compose(omitRequired, assoc("class", "checklist bb-option-list")),
  render: optionlist.render,
  onrequired: optionlist.onrequired,
  onreadonly: optionlist.onreadonly,
  allowUpdatingSelf: true,
  setValue: (input, value, requestor) => {
    const control = $(input).data("control");
    value.forEach(({ selected }, index) => {
      const option = input.querySelector(`[id="${control.id}-${index}"]`);
      if (!option) return;
      if (option.checked !== selected) option.checked = selected;
      $(option).trigger("programmatically-changed");
    });
  },
  layout: "below"
};

registerWidget(checklist);

const radiolist = {
  name: "radio",
  attribs: compose(omitRequired, assoc("class", "radiogroup bb-option-list")),
  render: optionlist.render,
  allowUpdatingSelf: true,
  onrequired: optionlist.onrequired,
  onreadonly: optionlist.onreadonly,
  setValue: (input, value, requestor) => {
    const control = $(input).data("control");
    const index = value.findIndex(option => option.selected === true);
    if (index === -1) {
      input.querySelectorAll(`[id|="${control.id}"]`).forEach(i => {
        i.checked = false;
        i.closest(".bb-option").classList.remove("checked");
      });
      return;
    }
    const option = input.querySelector(`[id="${control.id}-${index}"]`);
    option.checked = true;
    $(option).trigger("programmatically-changed");
  },
  layout: "below"
};

registerWidget(radiolist);

const datepicker = {
  name: "datetimepicker",
  couldWrap: true,
  fixup: control => {
    control.placeholder = _("dateplaceholder");
    return control;
  },
  serverToWidgetValue: value =>
    $.datepicker.formatDate(
      $.datepicker._defaults.dateFormat,
      parseDate(value)
    ),
  widgetToServerValue: input =>
    leadWithZeroes(
      $.datepicker.formatDate("yy-mm-dd", $(input).datepicker("getDate"))
    ),
  attribs: (attr, control, enabled) =>
    Object.assign({}, attr, {
      value: datepicker.serverToWidgetValue(control.value),
      autocomplete: "off",
      placeholder: _("dateplaceholder", _("select date")),
      type: "text"
    }),
  render: (control, group, attribs) => {
    return emptyTag("input", attribs);
  },
  afterRender: (widget, control) => {
    if (!control.originalreadonly || control.dynamic) {
      widget.datepicker();
      observeDatepickers();

      control._elt.addEventListener("change", onChangeDate);
      control._elt.addEventListener(
        "keydown",
        when(propEq("key", "Enter"), () => {
          const ev = new InputEvent("change", {
            bubbles: true,
            cancelable: true,
            view: window
          });
          control._elt.dispatchEvent(ev);
        })
      );

      disconnectValue({
        input: widget.get(0),
        filter: function (value, input) {
          return datepicker.widgetToServerValue(input);
        },
        value: control.value
      });
      widget.datepicker("widget").hide();
    }
  },
  setValue: (input, value) => {
    input.setAttribute("data-server-value", value);
    input.value = datepicker.serverToWidgetValue(value);
  },
  validate: (control, value) => {
    if (!control.notnull && (value || "").trim() === "") {
      // Side effect
      $($(control.$elt).datepicker("option", "altField")).attr("value", "");
    }
    return checkDate(control, value);
  },
  layout: "aside"
};

registerWidget(datepicker);

const numedit = {
  name: "numedit",
  couldWrap: true,
  getMaxChars: control => {
    var max, min;
    if (has("maximum", control))
      max = Numerals.formatter(
        control.maximum.toFixed(control.precision)
      ).length;
    if (has("minimum", control))
      min = Numerals.formatter(
        control.minimum.toFixed(control.precision)
      ).length;
    if (typeof min === "undefined") return max;
    if (typeof max === "undefined") return undefined;
    return Math.max(max, min);
  },
  serverToWidgetValue: value => Numerals.formatter(value),
  // widgetToServerValue: input => input.value,
  attribs: (attr, control, enabled) => {
    const maxchars = numedit.getMaxChars(control);
    return Object.assign(
      {},
      attr,
      {
        type: "text",
        class: "numedit",
        autocomplete: "off",
        name: control.name, // Prevent bug (in JSON grid) (see mantis#5416)
        //      value = control.value = parseFloat(control.value || 0);
        value: parseFloat(control.value || 0).toFixed(control.precision)
      },
      // maxchars -- not to be confused with maxlength, which used
      // for validation. This is just to aid layout decisions.
      maxchars && { "data-maxchars": maxchars }
    );
  },
  render: (control, group, attribs) => emptyTag("input", attribs),
  afterRender: (widget, control) => {
    widget.data("anchor", widget.spinner(control));
  },
  setValue: (input, value) => {
    const control = $.data(input, "control");
    input.value = numedit.serverToWidgetValue(
      parseFloat(value || 0).toFixed(control.precision)
    );
  },
  validate: function (control, value) {
    if (control.controltype !== "numedit")
      throw "_validateNumber called on something not a number";
    const metadata = control.metadata || {};
    var val;
    try {
      val = Numerals.parser(value);
    } catch (e) {
      throw new Error(
        format(
          metadata.errnuminvalid || _("Invalid number"),
          compose(assoc("value", value), numedit.quotable)(control)
        )
      );
    }
    control.hasMax = has("maximum", control);
    control.hasMin = has("minimum", control);
    try {
      if (typeof val !== "number")
        throw metadata.errnuminvalid || _("Invalid number");
      if (control.hasMax && control.hasMin) {
        if (val < control.minimum || val > control.maximum) {
          throw (
            metadata.errnumnotinrange ||
            _("Value has to lie between {minimum} and {maximum}")
          );
        }
      } else if (control.hasMax) {
        if (val > control.maximum) {
          if (control.maximum === 0)
            throw (
              metadata.errnumnotnegativeorzero ||
              _("Negative number or zero expected")
            );
          else if (control.maximum === -1)
            throw metadata.errnumnotnegative || _("Negative number expected");
          else
            throw (
              metadata.errnumabovemaximum ||
              _("Value has to lie below {maximum}")
            );
        }
      } else if (control.hasMin) {
        if (val < control.minimum) {
          if (control.minimum === 0)
            throw (
              metadata.errnumnotpositiveorzero ||
              _("Positive number or zero expected")
            );
          else if (control.minimum === 1)
            throw metadata.errnumnotpositive || _("Positive number expected");
          else
            throw (
              metadata.errnumbelowminimum ||
              _("Value has to lie above {minimum}")
            );
        }
      }
    } catch (e) {
      throw formatPlus(
        e,
        compose(assoc("value", value), numedit.quotable)(control)
      );
    }

    return true;
  },
  quotable: compose(
    when(has("minimum"), over(lensProp("minimum"), Numerals.formatter)),
    when(has("maximum"), over(lensProp("maximum"), Numerals.formatter)),
    when(
      has("minimum"),
      chain(assoc("minimum-1"), compose(dec, prop("minimum")))
    ),
    when(
      has("maximum"),
      chain(assoc("maximum+1"), compose(inc, prop("maximum")))
    ),
    quotable
  ),
  layout: "aside"
};

registerWidget(numedit);

const freebox = {
  name: "freebox",
  couldWrap: true,
  attribs: (attr, control, enabled) =>
    Object.assign({}, attr, {
      "type": "text",
      "data-type": control.controltype,
      "list": `${control.id}-list`,
      "placeholder": _("Choose..."),
      "value": freebox._realValue(control.value)
    }),
  _realValue: compose(propOr("", "option"), find(prop("selected"))),
  setValue: function (input, value, requestor) {
    input.value = freebox._realValue(value);
  },
  render: (control, group, attribs) => emptyTag("input", attribs),
  afterRender: (widget, control) => {
    control._elt.insertAdjacentHTML(
      "afterEnd",
      `<datalist id="${control.id}-list">
${control.value
  .map(({ option }) => `<option value="${escapeHTML(option)}">`)
  .join("")}
</datalist>
`
    );
  },
  layout: "aside"
};

registerWidget(freebox);

const _radioButtonWidget = {
  name: "radiobutton",
  tagName: "input",
  attribs: (attr, control, enabled) =>
    Object.assign(
      {},
      attr,
      { type: "radio", value: control.value },
      control.selected && { checked: "checked" }
    ),
  render: $.noop
};

registerWidget(_radioButtonWidget);

const _rangeWidget = {
  name: "range",
  tagName: "input",
  attribs: (attr, control, enabled) =>
    Object.assign({}, attr, {
      type: "range",
      value: control.value
    }),
  render: $.noop,
  layout: "aside"
};

registerWidget(_rangeWidget);

const _buttonWidget = {
  name: "button",
  attribs: (attr, control, enabled) =>
    Object.assign(
      {},
      attr,
      { type: "submit", value: control.value },
      control.label && { "aria-label": control.label }
    ),
  render: (control, group, attribs) => valueInside(control, attribs, "button"),
  setValue: (elt, value, requestor, Updates) => {
    if (has("text", Updates)) elt.innerHTML = Updates.text.to;
    if (has("value", Updates)) elt.value = Updates.value.to;
    elt.classList.toggle("bb-record-del", Updates.value.to.endsWith("-"));
    elt.classList.toggle("bb-record-add", Updates.value.to.endsWith("+"));
  }
};

registerWidget(_buttonWidget);

const _legendWidget = {
  name: "legend",
  tagName: "legend",
  attribs: noAttribs,

  render: function (control, widget, group) {
    var level;
    if (!conf.a11y.strictlegends && control._subtype === "heading") {
      level = Math.min(6, control._level || 2);
      // Trim value and control.value itself as well.
      widget.html(
        "<h" +
          level +
          ' class="bb-md-able">' +
          escapeHTML(control.value.trim()) +
          "</h" +
          level +
          ">"
      );
    } else {
      widget.text(control.value);
      widget.addClass("bb-md-able");
    }
  }
};

registerWidget(_legendWidget);

const _captionWidget = {
  name: "caption",
  tagName: "caption",
  attribs: noAttribs,
  render: function (control, widget, group) {
    widget.text(control.value.trim());
    widget.addClass("bb-md-able");
  }
};

registerWidget(_captionWidget);

const _alertWidget = {
  name: "alert",
  tagName: "p",
  attribs: noAttribs,
  render: function (control, widget, group) {
    widget.text(control.value);
    // Yup, this widget exists mainly for this
    // side-effect -- setting role="alert",
    // aria-live="assertive" does not do the
    // trick - and forcing focus looks ugly too.
    A11y.log(control.value);
  }
};

registerWidget(_alertWidget);

export const grid = {
  name: "grid",
  attribs: compose(omitRequired, omitName),
  item: item =>
    `<li class="bb-md-able">${escapeHTML(item).replace(/\\n/g, "<br/>")}</li>`,
  renderable: control => {
    const copy = assoc(
      "addPlacement",
      !control.addallowed
        ? "none"
        : control.value.length === 0 ||
            arbitraryCoreProp("putAddRowButtonBelowGrid")
          ? "below"
          : "insert",
      control
    );
    return pipe(
      when(
        propEq("addPlacement", "insert"),
        compose(
          over(
            lensProp("columns"),
            append({
              controltype: "button",
              text: `<span class="a-offscreen a-longtext">${
                (control.metadata && control.metadata.insertbuttontext) ||
                _("insert row")
              }</span><span class="a-just-a-symbol" aria-hidden="true">+</span>`,
              name: "update",
              className: "bb-record-add",
              readonly: false,
              label: _("insert row")
            })
          ),
          over(
            lensProp("value"),
            map((row, i) =>
              append(
                {
                  value:
                    control.name +
                    "." +
                    ((control.offset || 0) + i + 1) +
                    "." +
                    "+"
                },
                row
              )
            )
          )
        )
      ),
      when(
        propEq("deleteallowed", true),
        compose(
          over(
            lensProp("columns"),
            append({
              controltype: "button",
              name: "update",
              className: "bb-record-del",
              readonly:
                !control.notnull ||
                control.value.length > 1 ||
                (control.showcolumns && control.addallowed)
                  ? false
                  : true,
              text: `<span class="a-offscreen a-longtext">${
                (control.metadata && control.metadata.deletebuttontext) ||
                _("delete row")
              }</span><span class="a-just-a-symbol" aria-hidden="true">-</span>`
            })
          ),
          over(
            lensProp("value"),
            map((row, i) =>
              append(
                {
                  value:
                    control.name + "." + ((control.offset || 0) + i) + "." + "-"
                },
                row
              )
            )
          )
        )
      ),
      infichain([
        prop("columns"),
        (columns, grid) => {
          return over(
            lensProp("value"),
            map((row, row_i) =>
              map((cell, cell_i) =>
                compose(
                  when(
                    both(prop("colname"), isQuestion),
                    assoc("aria", { labelledby: `${control.id}-col-${cell_i}` })
                  ),
                  when(prop("colname"), cell =>
                    assoc("meta", { label: cell.colname }, cell)
                  ),
                  cell =>
                    Object.assign(
                      {},
                      columns[cell_i],
                      {
                        id:
                          control.id +
                          "-" +
                          ((control.offset || 0) + row_i) +
                          "-" +
                          cell_i
                      },
                      cell
                    ),
                  assoc("_row_index", (control.offset || 0) + row_i),
                  assoc("_cell_index", cell_i)
                )(cell)
              )(row)
            ),
            grid
          );
        }
      ]),
      over(
        lensProp("columns"),
        map((col, col_i) => assoc("id", `${control.id}-col-${col_i}`, col))
      ),
      over(
        lensProp("value"),
        map(
          map((cell, cell_i, cells) =>
            compose(
              assoc("_tag", grid.cellTag(cell, cells)),
              when(
                allPass([
                  isLabel,
                  pipe(prop("_cell_index"), gt, applyTo(0)),
                  () => isOption(cells[cell_i - 1])
                ]),
                cell => assoc("isFor", cells[cell_i - 1].id, cell)
              ),
              when(
                allPass([
                  isOption,
                  propEq("_cell_index", 0),
                  () => isLabel(cells[cell_i + 1])
                ]),
                cell =>
                  over(
                    lensPath(["aria", "labelledby"]),
                    col_id => `${cells[cell_i + 1].id} ${col_id || ""}`,
                    cell
                  )
              )
            )(cell)
          )
        )
      )
    )(copy);
  },
  cellTag: (cell, row) => {
    return cell.value === null
      ? null
      : cell._rowspan && cell._cell_index === 0 && row.length > 1
        ? "th"
        : "td";
  },
  columnheader: column => {
    const header = document.createElement("th");
    header.classList.add("bb-md-able");
    header.setAttribute("data-rowtype", column.controltype);
    header.setAttribute("data-rowreadonly", Boolean(column.readonly));
    if (has("colname", column)) {
      header.innerText = column.colname;
      header.id = column.id;
    }
    return header;
  },
  colheaderids: ifElse(
    prop("showcolumns"),
    compose(
      map(ifElse(propEq("controltype", "button"), F, prop("id"))),
      prop("columns")
    ),
    compose(map(F), prop("columns"))
  ),
  rowheaderid: (rows, i) => {
    const rows_up_to_here = rows.slice(0, i + 1);
    return compose(
      prop("value"),
      map(compose(id => `${id}--cell`, prop("id"), head)),
      Maybe.of,
      find(pathEq([0, "_tag"], "th")),
      reverse
    )(rows_up_to_here);
  },
  cell: cell => {
    const tag = cell._tag;
    const e_cell =
      tag === null
        ? document.createComment("empty cell")
        : document.createElement(tag);
    if (tag === "th") {
      e_cell.id = `${cell.id}--cell`;
    }
    return e_cell;
  },
  cellSetAttributes: (e_cell, cell) => {
    if (cell._tag === null) return e_cell;
    if (cell._tag === "th") e_cell.setAttribute("scope", "row");
    if (cell._rowspan > 1) e_cell.setAttribute("rowspan", cell._rowspan);
    else e_cell.removeAttribute("rowspan");
    if (cell.headers) e_cell.setAttribute("headers", cell.headers);
    else e_cell.removeAttribute("headers");
    if (typeof cell.colname !== "undefined")
      e_cell.setAttribute("data-column", cell.colname);
    else e_cell.removeAttribute("data-column");
    e_cell.classList.add("nth-child-" + (cell._cell_index + 1));
    if (cell.value === "")
      e_cell.classList.toggle(
        "empty",
        cell.controltype === "label" && cell.value === ""
      ); // IE8 does not support :empty

    e_cell.classList.add("bb-celltype-" + cell.controltype);
    return e_cell;
  },
  render: (control, group, attribs) => {
    const widget = $(`<table ${renderAttribs(attribs)}></table>`);
    control = grid.renderable(control);
    if (control.value.length === 0) {
      widget.addClass("bb-empty");
    }
    if (control.caption) {
      wControl(
        control.caption,
        group,
        widget //append hereto
      );
    }
    if (control.showcolumns) {
      // widget.append($("<col/>".repeat(control.columns.length)));
      const thead = document.createElement("thead");
      const tr = document.createElement("tr");
      thead.append(tr);
      widget.append(thead);
      if (control.value.length > 0)
        tr.append(...control.columns.map(grid.columnheader));
    }
    const e_body = document.createElement("tbody");
    widget.append(e_body);
    const colheaders = grid.colheaderids(control);
    control.value.forEach(function (row, i, rows) {
      // NOTE: control.offset is set by global plugin split-grid.
      //      const i = idx + (control.offset || 0);
      const last_spanning_cell_id = grid.rowheaderid(rows, i);
      const e_row = document.createElement("tr");
      if (row.length === 0) return;
      e_row.classList.toggle("bb-newscope", row[0]._tag === "th");
      e_body.appendChild(e_row);
      row.forEach(function (cell, icol) {
        const rowheader = cell._tag === "td" ? last_spanning_cell_id : false;
        const headers = [rowheader, colheaders[icol]].filter(Boolean).join(" ");
        const cellWithHeaders = {
          ...cell,
          headers
        };
        if (cell.value !== null) {
          const e_cell = grid.cell(cell);
          grid.cellSetAttributes(e_cell, cellWithHeaders);
          e_row.appendChild(e_cell);
          wControl(
            cell,
            control._group,
            e_cell //append hereto
          );
        } else {
          e_row.appendChild(document.createComment("empty cell"));
        }
      });
    });

    return $(widget);
  },
  afterRender: (widget, control) => {
    const addPlacement = !control.addallowed
      ? "none"
      : control.value.length === 0 ||
          arbitraryCoreProp("putAddRowButtonBelowGrid")
        ? "below"
        : "insert";
    if (addPlacement === "below") {
      const gridAddButton = `<button type="submit" name="update" class="bb-record-add" value="${
        control.name
      }${encodeURI(".+")}"><span>${
        (control.metadata && control.metadata.addbuttontext) || _("add row")
      }</span></button>`;
      widget.get(0).insertAdjacentHTML("afterEnd", gridAddButton);
      //      $(widget.in.append(widget, gridAddButton);
    }
  },
  setValue: (e_grid, value, requestor, updates) => {
    //    if (grid.contains(requestor))
    const control = $.data(e_grid, "control");
    const old = compose(
      grid.renderable,
      assoc("value", updates["value"].from),
      assoc("columns", updates["columns"].from)
    )(control);

    const now = grid.renderable(control);
    // Remove spurious row
    [
      ...e_grid.querySelectorAll(
        `tbody tr:nth-child(1n + ${now.value.length + 1})`
      )
    ].forEach(row => row.remove());
    // Remove spurious 'cells' (also empty cell comments)
    [...e_grid.querySelectorAll(`tbody tr`)].forEach(row => {
      let cell;
      while (((cell = row.childNodes[now.columns.length]), cell)) {
        cell.remove();
      }
    });

    if (
      !equals(old.columns, now.columns) ||
      (old.value.length === 0 && now.value.length !== 0) ||
      (old.value.length !== 0 && now.value.length === 0)
    ) {
      // Remove ALL column headers (re-created)
      [...e_grid.querySelectorAll(`thead tr th`)].forEach(colheader =>
        colheader.remove()
      );

      if (now.showcolumns && now.value.length > 0)
        now.columns // .slice(old.columns.length)
          .forEach(function (column) {
            e_grid.querySelector("thead tr").append(grid.columnheader(column));
          });
    }

    const colheaders = grid.colheaderids(now);
    now.value.forEach((row, irow, rows) => {
      let e_row = e_grid.querySelector(`tbody tr:nth-child(${irow + 1})`);
      if (e_row === null) {
        e_row = document.createElement("tr");
        e_grid.tBodies[0].append(e_row);
      }
      e_row.classList.toggle("bb-newscope", row[0]._tag === "th");
      const last_spanning_cell_id = grid.rowheaderid(rows, irow);
      row.forEach((cell, icol) => {
        const cellInput = e_grid.querySelector(`[id="${cell.id}"]`);
        const oldcell = old.value[irow] && old.value[irow][icol];
        const rowheader = cell._tag === "td" ? last_spanning_cell_id : false;
        const headers = [rowheader, colheaders[icol]].filter(Boolean).join(" ");
        const cellWithHeaders = {
          ...cell,
          headers
        };
        const spot = e_row.childNodes[icol];
        if (!spot) {
          // NEW => Add at end
          const e_cell = grid.cell(cell);
          grid.cellSetAttributes(e_cell, cellWithHeaders);
          e_row.appendChild(e_cell);
          wControl(cell, control._group, e_cell);
          return;
        }
        if (
          cell.controltype !== oldcell.controltype ||
          cell._tag !== oldcell._tag
        ) {
          // REPLACE
          let e_cell;
          if (cell._tag !== oldcell._tag) {
            e_cell = grid.cell(cell);
            spot.replaceWith(e_cell);
          } else {
            // Re-use old cell
            e_cell = spot;
            if (cell._tag !== null) {
              e_cell.classList.remove("bb-celltype-" + oldcell.controltype);
              e_cell.innerHTML = "";
            }
          }
          if (cell._tag !== null) {
            grid.cellSetAttributes(e_cell, cellWithHeaders);
            wControl(cell, control._group, e_cell);
          }
          return;
        }
        if (cell._tag === null) return;
        // UPDATE
        grid.cellSetAttributes(spot, cellWithHeaders);
        const cellControl = $.data(cellInput, "control");
        if (!cellControl) return;
        if (cell.value !== cellControl.value) {
          $(cellInput).updateControl(
            [
              Object.assign({}, cellControl, {
                value: cell.value,
                text: cell.text
              })
            ],
            requestor
          );
        }
      });
    });
  },
  onrequired: (grid, required) => {},
  onreadonly: (grid, readonly) => {
    grid.classList.toggle("bb-disabled", readonly);
    [...grid.querySelectorAll("input, textarea, button")].forEach(input => {
      input.disabled =
        readonly || compose(ifElse(Boolean, isReadOnly, F), getControl)(input);
    });
  },
  layout: "below"
};

registerWidget(grid);

$.fn.extend({
  /**
   * Widgetize as numedit
   */
  spinner: function (control) {
    control = control || {};
    var _interval = 250,
      interval = _interval,
      timer = null,
      me = this,
      val = Numerals.formatter(me.val()),
      $this = $(this),
      $anchor = $this;

    function mustAddButtons() {
      return !(arbitraryCoreProp("skipNumButtons") === true);
    }

    function addButtons($input, control) {
      var buttontemplate =
        '<div class="bb-num-buttons">' +
        '<div class="bb-num-up"></div>' +
        '<div class="bb-num-down"><div>' +
        "</div>";
      $input
        .wrapAll('<div data-type="numedit" class="bb-num"></div>')
        .after(buttontemplate);
      $input.parents(".bb-num").data("control", control);
      $input
        .next()
        .find(".bb-num-down, .bb-num-up")
        .on("click", adjustOnce)
        .on("mousedown", keepAdjusting)
        .on("mouseup mouseout", stopAdjusting);

      return $input.parent();
    }

    function adjust(diff) {
      var parsed, val;
      try {
        parsed = Numerals.parser(me.val());
      } catch (err) {
        $this.validate(); // Let validate() handle
        // showing of errors
      }
      if (typeof parsed === "undefined") return;
      // A diff of 0 is just used for the sake of formatting - so do
      // not force the number to be in range.
      val = (
        diff === 0
          ? parseFloat(parsed - diff)
          : forceInRange(
              parseFloat(parsed - diff),
              control.minimum,
              control.maximum
            )
      ).toFixed(control.precision || 0);

      me.val(Numerals.formatter(val));

      //if (val == "NaN") val = "";
      $this.validate(); // Validate just for the sake
      // of getting away the error
      // message
      if (me.data("orgval") !== me.val()) me.trigger("change");
      $this.data("orgval", $this.val());
    }

    $this
      .data("orgval", val) // Remember the original value
      .val(val)
      .on("keydown", function (e) {
        var key = e.keyCode;
        if (key === 38 || key === 40) {
          var diff = key === 38 ? -1 : 1;
          adjust(diff);
        }
        if (key === 13) {
          adjust(0);
        }
      })
      .on("click", function () {
        let parsed;
        try {
          parsed = Numerals.parser(me.val());
        } catch (err) {
          //
        } finally {
          if (parsed === 0) $this.val("");
        }
      })
      .on("blur", function () {
        let orgval = $this.val();
        adjust(0);
        if ($this.val() !== orgval) {
          $this.addClass("bb-programmatically-changed");
          window.setTimeout(function () {
            $this.removeClass("bb-programmatically-changed");
          }, 1000);
        }
        // Set orgval for future changes.
        $this.data("orgval", $this.val());
      });

    function keepAdjusting() {
      var thing = $(this);
      var diff = thing.hasClass("bb-num-down") ? 1 : -1;
      timer = window.setTimeout(function () {
        adjust(diff);
        if (interval > 20) interval = interval - 10;
        thing.trigger("mousedown");
      }, interval);
    }

    function adjustOnce() {
      var diff = $(this).hasClass("bb-num-down") ? 1 : -1;
      adjust(diff);
    }

    function stopAdjusting() {
      window.clearTimeout(timer);
      interval = _interval;
      return false; // prevent text selection
    }

    if (mustAddButtons()) $anchor = addButtons($this, control);

    disconnectValue({
      input: $this.get(0),
      filter: Numerals.parser,
      value: control.value
    });
    $this.on("change", function (_event, data) {
      if (!prop("programmatically", data)) $this.validate();
    });
    return $anchor;
  }
});
