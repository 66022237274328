let escapeHTML = (function () {
  var entityMap = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#39;",
    "/": "&#x2F;"
  };
  var re = new RegExp("[&<>\"'/]", "g");
  return function escapeHTML(string) {
    return String(string).replace(re, function (s) {
      return entityMap[s];
    });
  };
})();

const escaped = (strings, ...values) =>
  strings.reduce(
    (acc, cur, idx) =>
      acc + cur + (values.length > idx ? escapeHTML(values[idx]) : ""),
    ""
  );

export { escapeHTML, escaped };
