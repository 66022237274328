// Implements md-converter
import { default as MarkDown } from "markdown-it";

export { converter };

const converter = MarkDown({
  html: true,
  blankTarget: true,
  link: false
}).disable(["table", "strikethrough"]);

// Remember old renderer, if overridden, or proxy to default renderer
var defaultLinkRender =
  converter.renderer.rules.link_open ||
  function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options);
  };

converter.renderer.rules.link_open = function (
  tokens,
  idx,
  options,
  env,
  self
) {
  // If you are sure other plugins can't add `target` - drop check below
  const token = tokens[idx];
  const aIndex = token.attrIndex("target");
  const hrefIndex = token.attrIndex("href");
  const href = token.attrs[hrefIndex][1];

  if (!href.startsWith("#")) {
    if (aIndex < 0) {
      tokens[idx].attrPush(["target", "_blank"]); // add new attribute
    } else {
      tokens[idx].attrs[aIndex][1] = "_blank"; // replace value of existing attr
    }
  }

  // pass token to default renderer.
  return defaultLinkRender(tokens, idx, options, env, self);
};

converter.makeHtml = (html_in, only_phrasing) =>
  only_phrasing ? converter.renderInline(html_in) : converter.render(html_in);
