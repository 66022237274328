/* a11y-describedby:
 *
 * - Describe group with all its standard remarks
 * - Describe input by any non-standard remark labels directly
 *   preceding it
 *
 * Author: Niels Giesen
 * Copyright 2016 Berkeley Bridge
 *
 */
import { tap, when, compose, prop, path, find } from "$json/lib/functional";

$(document).on("bb:preHandleData", function (event, data) {
  if (data && data.groups) {
    $.each(data.groups, function (_, group) {
      var remarks = [],
        pretexts = [];
      $.each(group.controls, function (idx, control) {
        if (control["font-class"].toLowerCase() === "standard remark") {
          remarks.push(control.id);
        } else if (control.datatype && control.controltype === "label") {
          pretexts.push(control.id);
        }
        const explicitId = path(["metadata", "describedby"], control);
        if (explicitId) {
          compose(
            when(
              Boolean,
              compose(id => pretexts.push(id), prop("id"))
            ),
            find(c => c.identifier && c.identifier.endsWith(`.${explicitId}`)),
            prop("controls")
          )(group);
        }
        if (control.datatype && control.controltype !== "label") {
          maybeSetDescription(control, pretexts);
          pretexts = [];
        }
      });
      maybeSetDescription(group, remarks);
    });
  }
});

function maybeSetDescription(thing, description_ids) {
  if (description_ids.length) {
    thing.aria = thing.aria || {};
    thing.aria.describedby = description_ids.join(" ");
  }
}
