/******* positionalFormat() BEGIN ******/

/* https://github.com/pft/javascript/blob/master/positionalformat.js
 *
 * Copyright (C) 2006-2013 Niels Giesen.
 *
 *    Contact: <com dot gmail at nielsgiesen, in reversed order>
 *
 *    Author: Niels Giesen
 *    Keywords: JavaScript, formatting, String
 *
 *    This file is dual-licensed under either the BSD license or the
 *    GNU Affero General Public License.
 *
 *    positionalFormat enables you to replace numbers enclosed in curly braces (C# format
 *    apparently) with positional arguments (that can be reused), like
 *    this:
 *
 *    positionalFormat('argument { 1 } (or is it { 2 }, or { 0 }?) comes { 1 }',3,'first',1)
 *
 *    evals to:
 *
 *    "argument first (or is it 1, or 3?) comes first"
 */

export function positionalFormat(str) {
  var args = arguments;
  return str.replace(/{\s*(\d+)\s*}/g, function (match, num) {
    return args[parseInt(num) + 1] !== undefined
      ? args[parseInt(num) + 1]
      : match;
  });
}

/******* positionalFormat() END ******/

export function format(str, ob = {}) {
  return str.replace(/{\s*([^{]+?)\s*}/g, function (match, sub) {
    return ob[sub] !== undefined ? ob[sub] : sub;
  });
}

// export function formatPlus(str, ob = {}) {
//   return str.replace(/{\s*([^{]+?)\s*}/g, function (match, subwithstuff) {
//     const subs = subwithstuff.split(/\|/);
//     console.log(subs);
//     return subs.reduce((acc, cur) => {
//       if (ob[cur] === undefined) return acc;
//       return ob[cur];
//     }, subs[0]);
//     // if (ob[sub] === undefined) return sub;
//     // if (!directives) return ob[sub] !== undefined ? ob[sub] : sub;
//     // if (
//     //   directives.startsWith("+") &&
//     //   !Number.isNaN(ob[sub]) &&
//     //   !Number.isNaN(Number(directives.slice(1)))
//     // )
//     //   return Number(ob[sub]) + Number(directives.slice(1));
//     // if (
//     //   directives.startsWith("-") &&
//     //   !Number.isNaN(ob[sub]) &&
//     //   !Number.isNaN(Number(directives.slice(1)))
//     // )
//     //   return Number(ob[sub]) + Number(directives.slice(1));
//     // const [sub, directives] = subwithstuff.split(/\|/);
//     // if (ob[sub] === undefined) return sub;
//     // if (!directives) return ob[sub] !== undefined ? ob[sub] : sub;
//     // if (
//     //   directives.startsWith("+") &&
//     //   !Number.isNaN(ob[sub]) &&
//     //   !Number.isNaN(Number(directives.slice(1)))
//     // )
//     //   return Number(ob[sub]) + Number(directives.slice(1));
//     // if (
//     //   directives.startsWith("-") &&
//     //   !Number.isNaN(ob[sub]) &&
//     //   !Number.isNaN(Number(directives.slice(1)))
//     // )
//     //   return Number(ob[sub]) + Number(directives.slice(1));
//     return ob[sub];
//   });
// }

export const formatPlus = format;

// (str, ob = {}) {
//   return str.replace(/{\s*([^{]+?)\s*}/g, function (match, subwithstuff) {
//     const subs = subwithstuff.split(/\|/);
//     console.log(subs);
//     return subs.reduceRight((acc, cur) => {
//       if (ob[cur] === undefined) return acc;
//       return ob[cur];
//     }, subs[0]);
//     // if (ob[sub] === undefined) return sub;
//     // if (!directives) return ob[sub] !== undefined ? ob[sub] : sub;
//     // if (
//     //   directives.startsWith("+") &&
//     //   !Number.isNaN(ob[sub]) &&
//     //   !Number.isNaN(Number(directives.slice(1)))
//     // )
//     //   return Number(ob[sub]) + Number(directives.slice(1));
//     // if (
//     //   directives.startsWith("-") &&
//     //   !Number.isNaN(ob[sub]) &&
//     //   !Number.isNaN(Number(directives.slice(1)))
//     // )
//     //   return Number(ob[sub]) + Number(directives.slice(1));
//     // const [sub, directives] = subwithstuff.split(/\|/);
//     // if (ob[sub] === undefined) return sub;
//     // if (!directives) return ob[sub] !== undefined ? ob[sub] : sub;
//     // if (
//     //   directives.startsWith("+") &&
//     //   !Number.isNaN(ob[sub]) &&
//     //   !Number.isNaN(Number(directives.slice(1)))
//     // )
//     //   return Number(ob[sub]) + Number(directives.slice(1));
//     // if (
//     //   directives.startsWith("-") &&
//     //   !Number.isNaN(ob[sub]) &&
//     //   !Number.isNaN(Number(directives.slice(1)))
//     // )
//     //   return Number(ob[sub]) + Number(directives.slice(1));
//     return ob[sub];
//   });
// }
