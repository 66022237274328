import { fromApiServer } from "./location.js";
import Vars from "./vars.js";

if (!window.location.origin) {
  window.location.origin =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "");
}

/*** URL UTILITIES BEGIN ***/
export const urlutils = (function () {
  var mimetypes = {
    xsl: "text/html",
    html: "text/html",
    docx: "application/msword",
    pdf: "application/pdf",
    odt: "application/vnd.oasis.opendocument.text",
    rtf: "application/rtf",
    doc: "application/msword",
    jpg: "image/jpeg",
    jpeg: "image/jpeg",
    png: "image/png"
  };
  function caseReport(casus) {
    return fromApiServer("report?" + Vars.querify(Vars.SESSION_KEYS, casus));
  }
  function getFiles(casus) {
    return "getfiles?" + Vars.querify(Vars.SESSION_KEYS, casus);
  }
  function isJS(url) {
    return url.match(/javascript:/);
  }
  function isEmptyish(url) {
    return url[0] === "?";
  }
  var JSAlertMessage =
    "ALERT:\nIt looks like someone is trying to eavesdrop on you," +
    " trying to evaluate JavaScript code. " +
    "This person may want to steal private information " +
    "from you or other persons. " +
    "For your safety and that of others, " +
    "please contact this website " +
    "to report this message.";
  return {
    isJS,
    isEmptyish,
    JSAlertMessage,
    getFiles,
    caseReport,
    mimetype: function (url) {
      const absoluteURL = new URL(
        fromApiServer(url),
        `${location.protocol}${location.host}${location.pathname}`
      );
      const filename = absoluteURL.pathname.endsWith("/getfile")
        ? new URLSearchParams(absoluteURL.search).get("filename")
        : absoluteURL.pathname.split("/").pop();
      if (!filename) return null;
      const ext = filename.match(/\.([^?.]+)$/),
        mimetype = ext && mimetypes[ext[1]];
      if (!mimetype) return null;
      return {
        mimetype: mimetype,
        ext: ext[1]
      };
    }
  };
})();
/*** URL UTILITIES END ***/
