import { bb } from "$json";
/* questionlabelgroup-ng:
 *
 * Next-generation questionlabelgroup plugin.
 *
 * It will eventually probably replace the default implementation.
 *
 * Author: Niels Giesen
 * Copyright 2013 Berkeley Bridge
 *
 */
(function ($) {
  $(document).off("bb:questionlabelgroup");

  bb.questionlabelgroup = function () {
    $(".group:not(:has(.bb-questionlabelgroup))>.bb-label").each(function () {
      var $this = $(this),
        input = $this.prev("[type=checkbox]"),
        itype,
        control = $(this).data("control"),
        fieldset =
          bb.conf.a11y.optionfieldsets && control.controltype === "legend",
        $inner,
        $outer,
        classNames;

      if (!input.length)
        input = $this.nextUntil(
          ".bb-label, .bb-text, a, [type=checkbox], img, .clearfix," +
            "*[data-datatype]+ul" +
            (bb.conf.a11y.captions ? ", table" : "")
        );

      itype = input.data("type");
      $inner = $this.add(input);

      $outer = $(document.createElement(fieldset ? "fieldset" : "div"));

      if ($inner.length > 1) {
        classNames = [
          "bb-questionlabelgroup",
          "bb-itype-" + itype,
          "bb-fields-" + $inner.length
        ];

        // Direct children
        $.each($inner.filter("[data-type]"), function () {
          var $this = $(this);
          if ($this.attr("class")) {
            var classname = $this.attr("class").match(/\bbbm-[a-z0-9-]+\b/);
            if (classname) {
              classname = classname[0].replace(/\bbbm-/, "bb-g-");
              classNames.push(classname);
            }
          }
        });

        // Further offspring
        $.each($inner.find("[data-type]"), function () {
          var $this = $(this);
          if ($this.attr("class")) {
            var classname = $this.attr("class").match(/\bbbm-[a-z0-9-]+\b/);
            if (classname) {
              classname = classname[0].replace(/\bbbm-/, "bb-g-");
              classNames.push(classname);
            }
          }
        });

        // Propagate tags of immediate children to $outer
        $.each($inner, function (_, elt) {
          $.each($(elt).data("tags") || [], function (_, tag) {
            classNames.push("bb-child-tagged-" + tag);
          });
        });

        $outer.addClass(classNames.join(" "));
        $inner.wrapAll($outer);
      }
    });
  };
})(jQuery);
