/* global $ */
import "./a11y-user-detect.js";
import { _ } from "./gettext.js";

/*** A11y BEGIN ***/
export const A11y = {
  log: function (message) {
    var logdiv = document.getElementById("a-logdiv");
    if (logdiv) logdiv.innerHTML = "<div>" + message + "</div>";
  }
};

/**
 * Datepicker a11y enhancements
 */
export const observeDatepickers = (function () {
  var init = false;
  return function initDateObservance() {
    var picker, observer;

    if (init || !("MutationObserver" in window)) {
      init = true;
      return;
    }

    $(document).on("blur", '[data-type="datetimepicker"]', function () {
      A11y.log("");
    });

    function observation(records, instance) {
      try {
        var infocus =
          document.activeElement.className.split(" ").indexOf("hasDatepicker") >
          -1;
      } catch (e) {
        // there mayn't be an activeElement, in which case className is undefined.
      }
      if (infocus) {
        var message = [
          $(".ui-state-hover").text(),
          $(".ui-datepicker-month [selected]").text(),
          $(".ui-datepicker-year [selected]").text()
        ].join(" ");
        A11y.log(message + ", " + _("press enter to accept"));
      }
    }

    picker = document.getElementById("ui-datepicker-div");

    if (picker) {
      observer = new window.MutationObserver(observation);
      observer.observe(picker, { attributes: true });
      init = true;
    }
  };
})();
