/* global $ */

import { parseDate } from "./dates";
import { isReadOnly, isValidatable } from "./control-helpers";
import { updateLabels } from "./feature-queries.js";
import { conf } from "./conf.js";
import { getWidget } from "./form-widgets.js";
import { anyPass, has, prop } from "./functional.js";
const Dynprops = {};

// Update the control element
Dynprops.update = function ($widget, control, Updates, requestor) {
  if (!$widget.get(0)) return;
  const oldval = $widget.val();

  const updates = Object.keys(Updates);
  const definition = getWidget(control.controltype);
  $widget.removeData(["validated"]);

  /** isForNotNull **/
  if (has("isForNotNull", Updates)) {
    $widget.toggleClass("bb-for-required", Boolean(control.isForNotNull));
    $widget.toggleClass("bb-for-optional", !control.isForNotNull);
  }

  /** Placeholder **/
  if (has("placeholder", Updates) > -1) {
    $widget.attr("placeholder", control.placeholder);
  }

  /** Visible **/
  if (has("visible", Updates)) {
    if (control.identifier === "gformulier.gegevens.geschil_partner")
      $widget.attr("aria-hidden", !control.visible);
    $widget.attr("data-visible", control.visible);

    if (control.visible) {
      window.setTimeout(function () {
        $widget.removeAttr("hidden");
      }, 80);
    } else {
      window.setTimeout(function () {
        $widget.attr("hidden", !control.visible);
      }, 80);
    }
  }

  /** Value **/
  if (has("value", Updates)) {
    if (updateLabels(conf)) {
      /**
           @done: linklabel, listlabel, checkmultilist, memo, combobox, radio,
           checkbox, numedit, datetimepicker, edit, grid, multilist(?), listbox(?)
           @todo: freebox.

           NOTE: A widget currently can change itself *only* if
           they are registered with allowUpdatingSelf : true

           Widgets within a grid can update other widgets in a grid.
        */
      if (definition.setValue) {
        definition.setValue($widget.get(0), control.value, requestor, Updates);
      }
    }
  }

  /** Readonly **/
  if (
    anyPass([has("readonly"), has("originalreadonly"), has("visible")], Updates)
  ) {
    const readonly = isReadOnly(control);
    if (typeof definition.onreadonly === "function") {
      definition.onreadonly($widget.get(0), readonly);
    } else {
      $widget.prop("disabled", readonly);
    }
  }

  /** Minimum **/
  if (has("minimum", Updates)) {
    $widget.attr("min", control.minimum);
    if (control.controltype === "datetimepicker") {
      if (control.minimum) {
        $widget.datepicker("option", "minDate", parseDate(control.minimum));
      }
    }
  }

  /** Maximum **/
  if (has("maximum", Updates)) {
    $widget.attr("max", control.maximum);
    if (control.controltype === "datetimepicker") {
      if (control.maximum) {
        $widget.datepicker("option", "maxDate", parseDate(control.maximum));
      }
    }
  }

  /** Maxlength **/
  if (has("maxlength", Updates)) {
    if (control.maxlength === 0) $widget.removeAttr("maxlength");
    else $widget.attr("maxlength", control.maxlength);
  }

  /** Let user know something changed perhaps against their intent. **/
  if (oldval !== $widget.val()) {
    $widget.addClass("bb-programmatically-changed");
    $widget.trigger("change", { programmatically: true });
    self.setTimeout(function () {
      $widget.removeClass("bb-programmatically-changed");
    }, 1000);
  }

  /** Notnull **/
  if (has("notnull", Updates)) {
    if (typeof definition.onrequired === "function") {
      definition.onrequired($widget.get(0), control.notnull);
    } else {
      $widget.attr("aria-required", control.notnull);
    }
    if (control.notnull) {
      $widget.addClass("notnull");
    } else {
      $widget.removeClass("notnull");
    }
  }

  if (isValidatable(control)) {
    $widget.attr("aria-errormessage", `${control.id}--error`);
    $widget.addClass("validatable");
  } else {
    $widget.removeAttr("aria-errormessage");
    $widget.removeClass("validatable");
  }

  if (updates.length > 0) {
    const event = new CustomEvent("bb:updatedControl", {
      detail: { control, Updates },
      bubbles: true
    });
    $widget.get(0).dispatchEvent(event);
    $(document).trigger("bb:updated", [$widget, control, updates]);
  }
};

$.fn.extend({
  updateControl: function (controls, requestor) {
    return this.each(function () {
      const $this = $(this),
        control = $this.data("control");
      if (
        this === requestor &&
        !prop("allowUpdatingSelf", getWidget(control.controltype))
      ) {
        // This was the one requesting an update
        return this;
      }
      if (!control) throw ("No updateControl defined for", $this);
      const id = control.id,
        update = controls.find(function (c) {
          return c.id === id;
        }),
        //    updates = [],
        Updates = {},
        props = [
          "maxlength",
          "isForNotNull",
          "minimum",
          "maximum",
          "notnull",
          "readonly",
          "originalreadonly",
          "placeholder",
          "precision",
          "stringmask",
          "errortext",
          "visible",
          "value",
          "columns",
          "text"
        ];
      // Caron-syntax used in label, therefore no (text) interface
      // returned -- should not mix and match dynprops with empty
      // labels! Do not allow this to error on the user though!

      // Does also handle (cause to ignore) labels within a grid!
      if (typeof update === "undefined") {
        return this;
        // update = $.extend({}, control, {visible: false});
      }
      for (var i in props) {
        if (control[props[i]] !== update[props[i]]) {
          Updates[props[i]] = { from: control[props[i]], to: update[props[i]] };
          control[props[i]] = update[props[i]];
          //        updates.push(props[i]);
        }
      }
      Dynprops.update($this, control, Updates, requestor);
      return this;
    });
  }
});

export { Dynprops };
