import { _ } from "./gettext";

/*** NUMBER UTILITIES BEGIN ***/

export function forceInRange(num, min, max) {
  if (typeof min == "undefined" && typeof max == "undefined") return num;
  if (typeof min == "undefined") return Math.min(max, num);
  if (typeof max == "undefined") return Math.max(min, num);
  if (min === max) return min;
  return Math.max(Math.min(max, num), min);
}

export const Numerals = (function () {
  /**
   * Create a getter for array, consuming items, repeating the last one
   * indefinitely.
   *
   * @param {Array} arr The array to make a repeater for
   * @return {Function()} Gets next item in arr, repeating last item indefinitely
   */
  function makeLastItemRepeater(arr) {
    var _a = arr.slice();
    var _last;
    return function getIter() {
      _last = _a.shift() || _last;
      return _last;
    };
  }
  /**
   * Create a formatting function for (arabic-hindu) numerals.
   *
   * @param {String} radixPoint        String (character) used as radix point.
   * @param {String} groupingChar      String (character) used for grouping
   *                                   digits before the radix point.
   * @param {Array<Int>} groupingRules (optional) Array defining
   * grouping rules. Each element in this array stands for a group,
   * tracking leftwards from the radix point. When no more elements
   * remain, the last element will be repeated indefinitely.
   *
   * Most language use [3] (the default). For Indian, use [3,2]; for Chinese (and
   * Japanese?), use [4].
   *
   * Besides these rules, the special case of formatting
   * four digits in a row without grouping and no
   * fractional part is supported, and cannot be overruled.
   *
   * @return {Function(<Number>)} Formatting function.
   */
  function makeFormatter(radixPoint, groupingChar, groupingRules) {
    return function formatter(num) {
      var idx = makeLastItemRepeater(groupingRules || [3]);
      var split = num.toString().split(".");
      var integralPart = split[0];
      var fractionalPart = split[1];
      var neg = (integralPart[0] === "-" && "-") || "";
      if (neg) integralPart = integralPart.substr(1);
      var th,
        out = "";
      if (integralPart) {
        // The four-digit exception rule (such as in years):
        if (!fractionalPart && integralPart.length === 4) out = integralPart;
        else {
          integralPart = integralPart.split("").reverse();
          while ((th = integralPart.splice(0, idx()))[0]) {
            out = th.reverse().join("") + (out ? groupingChar : "") + out;
          }
        }
      }
      if (fractionalPart) return neg + out + radixPoint + fractionalPart;
      return neg + out;
    };
  }
  function makeParser(radixPoint, groupingChar) {
    /**
     * Match anything not a digit or negative sign
     */
    var thre = /[^0-9-]/g;
    /**
     * Match only optional leading minus digits,
     * radixPoint, groupingChar and space characters.
     */
    var testre = new RegExp("^s?-?[0-9 " + radixPoint + groupingChar + "]*$");

    return function parser(s) {
      if (!testre.test(s)) throw new Error(_("Invalid number"));
      var split = s.split(radixPoint);
      var integralPart = split[0].replace(thre, "");
      if (!/^-?[0-9]*$/.test(integralPart))
        throw new Error(_("Invalid number"));
      var fractionalPart = split[1];
      if (fractionalPart) fractionalPart = fractionalPart.replace(/\s/g, "");
      if (!fractionalPart) fractionalPart = "0";
      if (!/^[0-9]*$/.test(fractionalPart))
        throw new Error(_("Invalid number"));
      // Room for errors here.
      return new Number(integralPart + "." + fractionalPart) + 0;
    };
  }

  return {
    parser: makeParser(
      _("__radixpoint__", "."),
      _("__digitgroupingchar__", ",")
    ),
    formatter: makeFormatter(
      _("__radixpoint__", "."),
      _("__digitgroupingchar__", ","),
      _("__digitgroupingrules__", [3])
    ),
    makeFormatter: makeFormatter,
    makeParse: makeParser,
    refresh: () => {
      //      console.log(this);
      Numerals.parser = makeParser(
        _("__radixpoint__", "."),
        _("__digitgroupingchar__", ",")
      );
      Numerals.formatter = makeFormatter(
        _("__radixpoint__", "."),
        _("__digitgroupingchar__", ","),
        _("__digitgroupingrules__", [3])
      );
    }
  };
})();
/*** NUMBER UTILITIES END ***/
