import { compose, either, isNil, not, path } from "./functional.js";

/* editPolicy may be "return", "stay", or undefined, meaning off */
export const editPolicy = path(["arbitrary", "core", "editPolicy"]);

export const canEditEarlier = compose(not, isNil, editPolicy);

const isLabelValueDynamic = compose(
  value => value === true,
  path(["arbitrary", "core", "isLabelValueDynamic"])
);

const preferUpdate = compose(
  value => value === true,
  path(["arbitrary", "core", "preferUpdate"])
);

export const mustUpdate = either(preferUpdate, canEditEarlier);

export const updateLabels = either(mustUpdate, isLabelValueDynamic);
