/* global $, URLSearchParams */
import { setSettled } from "./settled.js";
import { removeEndListener } from "./case-exit.js";

function BBI(options) {
  options = options || {};
  if (options.redirect_uri) {
    /** Example response:
     *
     * {
     * "bbis" : "test",
     * "authid" : "Stubby",
     * "redirect_uri" : "https:\/\/HOST:8078\/login?returnurl=http%3A%2F%2Fhost%3A80%2Fbbisreturns%3Fbbis%3Dtest&state=1507301556%3AGUID&authid=Stubby",
     * "url": "http:\/\/HOST:80\/bbisreturns?bbis=test",
     * "state": "1507301556:6F2146D3-E0E6-4EB2-9DE4-2F31670B80D6"
     * }
     *
     */
    var path = window.location.pathname.split("/"),
      template = path.pop() || "inlog.html",
      returnurl =
        window.location.origin +
        path.concat(template).join("/") +
        "?" +
        $.param({ bbis: options.bbis }),
      server = options.redirect_uri.split("?")[0]; // The identity server sans params
    this.params = Object.assign({}, options.extraparams, {
      returnurl,
      authid: options.authid,
      // Add specific HTML page to state:
      state: options.state
    });
    this.url = server + "?" + $.param(this.params);
    this.stage = 1;
  } else {
    this.params = $.extend($.parseQuery(), { fmt: "json" });
    this.stage = 2;
  }
  return this;
}

BBI.prototype.authenticate = function () {
  if (!this.stage) throw "BBI was not properly initialized";
  if (this.stage === 1) {
    removeEndListener();
    window.location.href = this.url;
  } else if (this.stage === 2) {
    $.postJSON("bbisreturns", this.params).then(setSettled);
  }
};

export { BBI };
