import { positionalFormat } from "./text-utils.js";
import { _ } from "./gettext.js";

const CODES = {
  cException: 1,
  cHTMLFormLoadCaseNotFound: 2,
  cHTMLMenuFileNotFound: 3,
  cWebModelIDDifferent: 4,
  cWebPleaseLogin: 5,
  cGetFileNotAllowed: 6,
  cGetDocumentNotFound: 7,
  cUMWrongUserNamePassword: 8,
  cWebSessionTimeOut: 9,
  cWebNoModelID: 10,
  cWebIllegalAccess: 11,
  cWebModelNotFound: 12,
  cWebCaseIsRunning: 13,
  cEngineLoadCaseFailed: 14, // Was: 3
  cEngineUpgrading: 15,
  cEngineNoInterfacesToShow: 16,
  cEngineLoadCaseFailedNotFound: 17,
  cEngineLoadCaseFailedWrongType: 18,
  cEngineLoadCaseFailedWrongData: 19,
  cWebOpenIsCalledWithUniqueID: 20,
  cTechGraphVizNotInstalled: 21,
  cGraphVizSessionNotFound: 22,
  cUMUserNotFound: 23,
  cUMModelNotFound: 24,
  cUMCaseNotFound: 25,
  cWebAdminNotAllowed: 26,
  cEngineGotoNodeFailed: 27,
  cPreviewNoCaseFound: 28,
  cTechUploadedFileToLarge: 29,
  cWebSaveCaseNotAllowed: 30,
  cWebSaveCaseFailed: 31,
  cInvalidUpload: 53
};

const CODESREV = Object.fromEntries(
  Object.entries(CODES).map(([key, value]) => [value, key])
);

// Translate error - put translation in message property, return
// error.
function translate(_error) {
  const error = Object.assign({}, _error);
  // New API
  switch (error.code) {
    case CODES.cException:
      error.message = _(
        "Something went wrong; see the server log for more information."
      );
      break;
    case CODES.cWebSessionTimeOut:
      error.message = _("Session has timed out. Please log in again.");
      break;
    case CODES.cEngineLoadCaseFailed:
      switch (error.subcode) {
        case 1004:
          error.message = positionalFormat(
            _('You have no tickets left for model "{0}"'),
            error.summary
              .substring(56, error.summary.length - 1)
              .replace(/_/g, " ")
          );
          break;
        default:
          error.message = error.summary; // Won't translate for now, may be st. like "Loading case failed (xxx.mdl)"
      }
      break;
    case CODES.cEngineLoadCaseFailedWrongData:
      error.message = _("Error loading session (wrong data)");
      break;
    default:
      switch (error.subcode) {
        case 1002:
          error.message = _(
            "We are currently updating. Please try again later."
          );
          break;
        case 1009:
        case 1025:
          error.message = positionalFormat(
            _('Infinite loop detected in {0}"'),
            // Might be embedded though (in 1 or in 14...)
            error.summary.slice(18, error.summary.indexOf(", infinite"))
          );
          break;
        case 1010:
          error.message = _(
            "Interface has failed (probably a failing SOAP or database connection)"
          );
          break;
        default:
          error.message = _(CODESREV[error.code], _(error.summary));
      }
  }
  return error;
}

export { CODES, translate };
