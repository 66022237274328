const Hooks = new Map();

export const registerHook = key => hook => {
  if (typeof hook !== "function") throw "Can only add a function as a hook";
  Hooks.set(key, hook);
};

export const runHook =
  key =>
  (...args) => {
    if (Hooks.has(key)) Hooks.get(key)(...args);
  };
