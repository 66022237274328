/* history:
 *
 * Manage history using the html5 history api and sessionStorage
 *
 * Could have support for crunchbang ( #!modelname=thisandthat )
 *
 * Author: Niels Giesen
 * Copyright 2013, 2014, 2015 Berkeley Bridge
 *
 */
import { bb, _ } from "$json";
import { has } from "$json/lib/functional";
import { setSettled } from "$json/lib/settled";

(function ($, win, history, location) {
  var crunchbang = false;

  if (history && history.pushState) {
    if (!location.origin) {
      location.origin =
        location.protocol +
        "//" +
        location.hostname +
        (location.port ? ":" + location.port : "");
    }

    $(function () {
      var apinav,
        storage,
        params = $.parseQuery(location.hash.slice(2)),
        _title_sep = " ",
        _title = document.title;

      try {
        storage = window.sessionStorage;
      } catch (err) {
        storage = null;
      }

      function directory(loc) {
        return loc.origin + loc.pathname.replace(/[^/]+$/, "");
      }

      function restoreState(state) {
        if (state.sessionid) {
          // restore case
          bb.ajax
            .post({
              url: "action",
              data: state
            })
            .then(setSettled);
        } else if (bb.getVar("sessionid")) {
          // exit running case and restore model view
          bb.exit(() => bb.menu(state).then(setSettled));
        } else {
          // restore model view
          bb.menu(state).then(setSettled);
        }
      }

      function ditchState() {
        storage && storage.removeItem("state");
        history.replaceState(null, null, null);
        setSettled();
      }

      if (location.search !== "") ditchState();

      var initial_state =
        history.state || (storage && JSON.parse(storage.getItem("state")));

      if (initial_state) {
        // Detect explicit edit of the crunchbang - this should
        // override the dbname parameter and instead use the modelname
        if (params.modelname && params.modelname !== initial_state.modelname) {
          initial_state.modelname = params.modelname;
          delete initial_state.dbname;
          delete initial_state.sessionid;
        }

        if (initial_state.directory === directory(location)) {
          bb.Mode.set("isLoggedIn"); // Yes, just an assumption it will go allright.
          restoreState(initial_state);
        } else {
          ditchState();
        }
      } else if (params.modelname) {
        // Bookmarked with crunchbang, but no state
        bb.Router(params);
      } else {
        setSettled();
      }

      $(document).on("bb:jsonError", ditchState);

      $(document).on("bb:preHandleData", function (event, data) {
        if (data && data.uniqueid) {
          var title,
            state = {
              fmt: "json",
              uniqueid: data.uniqueid,
              // Save the 'directory part'
              directory: directory(location)
            };
          if (has("dbname", data) && data.sessionid) {
            state.dbname = data.dbname;
            state.sessionid = data.sessionid;
            state.modelname = data.modelname; // Needed to get interpret explicit change to location

            if (data.modeldescription) {
              title = [_title, data.modeldescription.replace(/_/g, " ")].join(
                _title_sep
              );
            }
          } else if (data.models) {
            var model = $.grep(data.models, function (m) {
                return m.selected;
              })[0],
              dbname = model ? model.dbname : null;
            if (dbname !== null) {
              state.dbname = dbname;
              title = [_title, model.modelname, "-", _("overview")].join(
                _title_sep
              );
            } else {
              title = [_title, _("Your models")].join(_title_sep);
            }
          }
          if (!apinav) {
            try {
              storage && storage.setItem("state", JSON.stringify(state));
            } catch (e) {
              // Guard against QuotaExceededError (which always happens on Safari Private Browsing on iOS)
            }
            if (history.state && history.state.sessionid && state.sessionid) {
              history.replaceState(
                state,
                null,
                location.origin +
                  location.pathname +
                  ((crunchbang && "#!modelname=" + data.modelname) || "")
              );
            } else {
              if (data.modelname && crunchbang) {
                history.pushState(
                  state,
                  null,
                  location.origin +
                    location.pathname +
                    "#!modelname=" +
                    data.modelname
                );
              } else {
                history.pushState(
                  state,
                  null,
                  location.origin + location.pathname
                );
              }
            }
          }
          if (title && document.title !== title) {
            document.title = title;
          }
        }
        // Something went miserably wrong. Clear history to get out of this mess.
        if (
          data &&
          ((data.error && data.error.summary) ||
            (data.groups && !data.groups.length) ||
            data.status === "logout successful")
        ) {
          // Extra check - may be we are but updating
          if (
            data &&
            data.error &&
            data.error.code &&
            data.error.code === 14 && // Error loading case
            data.error.subcode === 1002
          )
            // Updating
            return;
          ditchState();
        }
        apinav = false;
      });

      win.addEventListener("popstate", function (e) {
        apinav = true;
        if (e.state) {
          if (e.state.directory === directory(location)) restoreState(e.state);
          else ditchState();
        }
      });
    });
  }
})(jQuery, window, window.history, window.location);
