/*
 * metadata-change-navtext
 *
 * Checks for metadata per node, specifically for
 * keys to change the nav button texts.
 *
 * Author: Tim Bauwens
 * Copyright: Berkeley Bridge 2020
 */

import { has } from "$json/lib/functional";
import { getmetadata } from "$json/lib/getmetadata";

$(document).ready(() => {
  ((doc, $) => {
    let classes = ["bb-next", "bb-prior", "bb-skip", "bb-restart"];

    let originals = {};

    let flagged = [];

    classes.forEach(cls => {
      let button = doc.querySelector(`.${cls}`);
      if (!button) {
        // Remove this class, the button doesn't exist.
        flagged.push(cls);
      } else {
        let affected = button.querySelector(".text");
        if (!affected) {
          // Remove this class, and warn the user the template is not supportive.
          flagged.push(cls);
          window.console &&
            window.console.warn(
              `Using metadata-change-navtext with a non-supporting template.
              Please wrap nav strings with <span class="text></span>"`
            );
        } else {
          originals[cls] = affected.innerText.trim();
        }
      }
    });

    classes = classes.filter(cls => !flagged.includes(cls));

    const reset = () => {
      // Reset strings to orig. values.
      classes.forEach(cls => {
        replace(cls, originals[cls]);
      });
    };

    const replace = (cls, string) => {
      doc.querySelectorAll(`.${cls}`).forEach(btn => {
        btn.querySelector(".text").innerText = string;
      });
    };

    $(doc).on("bb:finalHandleData", (e, data) => {
      if (data && data.groups && data.groups.length) {
        reset();
        const groups = getmetadata(data).groups;
        let currentgroupid = data.groups.reduce(
          (id, group) => (group.current ? group.groupid : false),
          false
        );
        groups.forEach((group, id) => {
          if (id === currentgroupid) {
            classes.forEach(cls => {
              has(cls)(group) && replace(cls, group[cls]);
            });
          }
        });
      }
    });
  })(document, jQuery);
});
