/*
  autostart.js

  A local helper plugin to auto start
  the one and only model without
  having to log in to the server first.

  Author: Tim Bauwens
  Copyright: xxllnc 2022

*/
import { bb } from "$json";
import { setSettled } from "$json/lib/settled";
var start;

((doc, $) => {
  start = e => {
    let params = {
      username: "",
      password: "",
      modelname: "agroconnect"
    };
    $.postJSON("open", $.param(params) + "&fmt=json").then(setSettled);
  };
  doc.addEventListener("DOMContentLoaded", start, false);
  $(doc).on("bb:preHandleData", (e, data) => {
    start = $.noop();
  });
})(document, jQuery);
