import { Mode } from "./mode.js";

/*** NOTIFICATIONS BEGIN ***/
let message;
// Notification can either be a String or an object with a message
// property. When supplying an object, the message property will be
// shown to the user, but the entire object will be given to
// console.error.
function notify(options = { keepalive: false, html: false }, notification) {
  if (!notification) return;
  var text = notification.message || notification,
    timeout = 5000;
  if (
    typeof console !== "undefined" &&
    console &&
    typeof console.error === "function"
  )
    console.error(notification);
  message = { notification, options };
  Mode.set("hasMessage");
  if (options.html) {
    document
      .querySelectorAll(".bb-notification")
      .forEach(area => (area.innerHTML = text));
  } else {
    document
      .querySelectorAll(".bb-notification")
      .forEach(area => (area.textContent = text));
  }
  if (!options.keepalive)
    window.setTimeout(Mode.unset.bind(Mode, "hasMessage"), timeout);
}

export { notify, message };
/*** NOTIFICATIONS END ***/
