/* global $ */
import { bbmClass, isReadOnly, renderAttribs } from "./control-helpers";
import { Dynprops } from "./dynprops";
import { escaped, escapeHTML } from "./escape.js";
import { conf, propFinder } from "./conf";
import { getWidget } from "./form-widgets.js";
import {
  assoc,
  both,
  compose,
  complement,
  has,
  hasPath,
  isNil,
  not,
  prop
} from "./functional.js";
import { runHook } from "./hooks.js";
import { Mode } from "./mode.js";

const arbitraryCoreProp = propFinder(conf, "arbitrary.core");

/**
 * Create a control widget, then insert it into the DOM
 *
 * @param {Object}  control     A control object as defined in the JSON API. The one to render + add.
 * @param {Object}  group       The group object to which this control belongs.
 * @param {Element} wGroup      Element whereto this control should be added. *NOTE* that this need not be a <tt>.bb-group</tt>
 *
 * @return undefined
 *
 * @todo Simplify parameter list.
 */
function wControl(control, group, wGroup) {
  control._group = group;
  let attribs = {};
  let widget;
  const enabled = group.current || complement(isReadOnly)(control);
  // Has been rendered
  if (control.$elt) {
    $(wGroup).append(control.$elt);
    return control.$elt;
  }
  if (compose(has("fixup"), getWidget)(control.controltype))
    compose(fn => fn(control), prop("fixup"), getWidget)(control.controltype);

  if (control.datatype) attribs["data-datatype"] = control.datatype;

  if (control.meta)
    for (let d in control.meta) {
      if (both(has(d), compose(not, isNil, prop(d)))(control.meta))
        attribs["data-" + d] = escape(control.meta[d]);
    }
  if (control.metadata) {
    attribs["data-metadata-keys"] = Object.keys(control.metadata)
      .map(s => s.replace(/\s/g, "-"))
      .join(" ");
  }
  if (control.aria)
    for (let a in control.aria) {
      if (has(a, control.aria)) attribs["aria-" + a] = control.aria[a];
    }

  if (control.name) {
    attribs["name"] = control.name;
  }

  const wdef = getWidget(control.controltype);
  if (!wdef) {
    console.warn(`No widget definition for ${control.controltype}`);
    return null;
  }
  const tagName = wdef.tagName;
  attribs = wdef.attribs(attribs, control, enabled);

  attribs["data-type"] = control.controltype;
  if (tagName) {
    widget = $("<" + tagName + " " + renderAttribs(attribs) + "/>");
  } else {
    widget = $(wdef.render(control, group, attribs));
    if (!widget) return null;
  }

  if (control.className) {
    widget.addClass(control.className);
  }
  control.$elt = widget;
  control._elt = widget.get(0);

  if (wdef && wdef.tagName) {
    /**
       Okay, some (older) definitions have a tagName definition ->
       from which a quite empty skeleton widget is created.

       Afterwards, they fill that very widget with a render function
       of a different signature than usual:

       Instead of (control (plain object), group  (plain object), attribs (array)), they get
       (control (plain object), widget (jQuery collection), group (plain object))).

    */
    wdef.render(control, widget, group);
  }

  // attach an id whenever meaningful:
  // if (control.id && /\d+$/.test(control.id))
  if (control.id) {
    // id used not to be safe, but now we prepend the groupid, making it safe.
    widget.attr("id", control.id);
  }
  if (control._originalid) {
    // Use data-id for stuff refering to the interface, no matter in which group it is, such as informationsources.
    widget.attr("data-id", control._originalid);
  }

  if (hasPath(["metadata", "autocomplete"], control)) {
    widget
      .attr("data-server-name", control.name)
      .attr("name", control.metadata.autocomplete)
      .attr("autocomplete", control.metadata.autocomplete);
  }

  $(wGroup).append(widget);

  /* Insert a space between elements so that elements are reasonably
   * well-placed when CSS is disabled.
   */
  $(wGroup).append(" ");

  // Tooltips:
  if (control.hint) {
    if (enabled) Mode.set("hasHints");
    runHook("hinter")(widget, assoc("enabled", enabled, control));
  }
  widget.data("control", control);

  /**
   * Dynamic properties
   */
  var dynprops = [
      "maxlength",
      "minimum",
      "maximum",
      "notnull",
      "isForNotNull",
      "readonly",
      "precision",
      "stringmask",
      "errortext",
      "placeholder"
    ],
    dynpropsforus = {};

  for (var ii in dynprops) {
    if (has(dynprops[ii], control))
      dynpropsforus[dynprops[ii]] = { to: control[dynprops[ii]] }; // .push(dynprops[ii]);
  }

  if (has("visible", control) && control.visible === false) {
    dynpropsforus["visible"] = { to: false }; // .push(dynprops[ii]);
  }
  // dynpropsforus.push("visible");

  widget.data("anchor", widget);

  if (wdef && wdef.afterRender) {
    wdef.afterRender(widget, control);
  }

  if (shouldWrap(control)) {
    wrapInlineInput(widget);
  }

  /* Allow class-based styling */
  if (control["font-class"]) {
    widget.addClass(bbmClass(control["font-class"]));
  }

  /**
   * Two things:
   * - Put control tags in data-tags of Element.
   * - Make TAG known to CSS as bb-tagged-TAG.
   */
  if (control["tags"] instanceof Array) {
    $(widget).data("tags", control["tags"]);
    $.each(control["tags"], function (i, tag) {
      widget.addClass("bb-tagged-" + tag);
    });
  }

  Dynprops.update(widget, control, dynpropsforus);

  return widget;
}

function shouldWrap(control) {
  return (
    control.prelabel ||
    control.postlabel ||
    (arbitraryCoreProp("wrapAllSingleLiners") === true &&
      compose(prop("couldWrap"), getWidget)(control.controltype)) ||
    (control.placeholder &&
      arbitraryCoreProp("accessiblePlaceholders") === true &&
      compose(prop("couldWrap"), getWidget)(control.controltype))
  );
}

function wrapInlineInput(widget) {
  var control = widget.data("control"),
    prelabel = control.prelabel,
    postlabel = control.postlabel;
  var anchor = widget.data("anchor");
  var wraptag = "span";
  if (anchor.get(0).nodeName === "DIV") wraptag = "div";
  anchor.wrapAll(
    "<" +
      wraptag +
      ' data-wraps-type="' +
      control.controltype +
      '" class="bb-input-wrap"></' +
      wraptag +
      ">"
  );
  anchor = anchor.parent();
  widget.data("anchor", anchor);
  anchor.data({
    control: control,
    type: control.controltype
  });
  if (prelabel)
    anchor.prepend(
      '<span class="bb-input-unit bb-input-unit--pre">' +
        escapeHTML(prelabel) +
        "</span>"
    );
  if (control.placeholder && arbitraryCoreProp("accessiblePlaceholders")) {
    widget
      .get(0)
      .setAttribute(
        "aria-describedby",
        (
          (widget.get(0).getAttribute("aria-describedby") || "") +
          ` ${control.id}--placeholder`
        ).trim()
      );
    anchor.append(
      `<span class="bb-input-placeholder" id="${
        control.id
      }--placeholder">${escapeHTML(control.placeholder)}</span>`
    );
  }
  if (postlabel)
    anchor.append(
      '<span class="bb-input-unit bb-input-unit--post">' +
        escapeHTML(postlabel) +
        "</span>"
    );
}

const getControl = elt => $.data(elt, "control");

export { getControl, wControl };
