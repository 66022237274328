/* headings:
 *
 * Translate .heading* into real headings, starting at level 2
 *
 * Author: Niels Giesen
 * Copyright 2016 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  var level = 1;

  $(function () {
    $(doc).on("bb:preHandleData", function (event, data) {
      if (data && data.groups && data.groups.length) {
        $.each(data.groups, function (_, group) {
          $.each(group.controls, function (_, ctl) {
            if (ctl.controltype === "label") {
              var klass = ctl["font-class"].toLowerCase();
              if (klass.indexOf("heading") === 0) {
                ctl._subtype = "heading";
                ctl._level = (parseInt(klass.substr(7)) || 0) + level;
              }
            }
          });
        });
      }
    });
  });
})(jQuery, window, document);
