/* global URLSearchParams FormData */

import { mapConcat, either, when } from "./functional.js";

const ATTR_SNAME = "data-server-name";
const ATTR_SVALUE = "data-server-value";
const CONTAINER_SELECTOR = "#bb-q .group.selected";

const INPUT_SELECTOR = mapConcat(
  ["input", "textarea", "select"],
  function (c) {
    return c + "[name]:not([disabled])";
  },
  ", "
);

const inputsOf = container => container.querySelectorAll(INPUT_SELECTOR);

const isEmptyRadioValue = val => val === null;
const isEmptyCheckboxValue = val => val === false;
const isCheckedCheckboxValue = val => val === true;

function collectWithin({
  params = null, // Object with an .append method, taking two params
  flavor = URLSearchParams, // Constructor for an object with an .append method, taking two params, used when params is not provided
  container = document.querySelector(CONTAINER_SELECTOR),
  omitFn = isEmptyCheckboxValue,
  changeFn = when(isCheckedCheckboxValue, () => "on"),
  collector = inputsOf
} = {}) {
  return _collectAll(
    collector(container),
    params || new flavor(),
    omitFn,
    changeFn
  );
}

function _collectAll(nodeList, params, omitFn, changeFn) {
  for (const node of nodeList) {
    const val = valueOf(node),
      name = node.getAttribute(ATTR_SNAME) || node.getAttribute("name");
    if (!either(isEmptyRadioValue, omitFn, val)) {
      params.append(name, changeFn(val));
    }
  }
  return params;
}

function valueOf(node) {
  if (node.hasAttribute(ATTR_SVALUE)) return node.getAttribute(ATTR_SVALUE);
  switch (node.type) {
    case "radio":
      return node.checked ? node.value : null; // Do not collect unchecked radios -- stripped in collectAll.
    case "checkbox":
      /* Collect unchecked checkboxes as false -- goes against usual form submission,
         but we need this for Studio API (using JSON).  Stripped out by collectAll() unless provided with a omitFn. */
      if (!node.checked) return false;
      return node.hasAttribute("value") // NOT the property -- this would still be "on".
        ? node.value // A checkbox should send either its value or
        : true; // Return Boolean true instead of "on", to be changed by collectAll() with changeFn,
    // or leave it at true (for JSON communication for instance) */
    default:
      return String(node.value).replace(/\r?\n/g, "\r\n");
  }
}

const serializeQuestions = () =>
  collectWithin({}).toString().replace(/\r?\n/g, "%0D%0A");

export { valueOf, serializeQuestions, collectWithin };
