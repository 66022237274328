import { escapeHTML } from "./escape.js";
import {
  choose,
  compose,
  equals,
  findIndex,
  T,
  identity,
  slice
} from "$json/lib/functional.js";

const times = (string, integer) => string.repeat(integer);

/*** JUMPLIST BEGIN ***/
function hasselectedbelowlevel(jumplist, level) {
  if (jumplist.length == 0) return false;
  if (jumplist[0].level <= level) return false;
  if (jumplist[0].status == "selected") return true;
  return hasselectedbelowlevel(jumplist.slice(1), jumplist[0].level);
}
function hasselectedbelow(jumplist) {
  return hasselectedbelowlevel(jumplist.slice(1), jumplist[0].level);
}

const ariaCurrentAttrib = jumplist =>
  jumplist.status === "selected" ? ` aria-current="step"` : "";

const chapterDataAttrib = jumplist =>
  !jumplist.ischapter ? "" : `data-chapterstatus=${jumplist.chapterstatus}`;

function bakeInnerList(jumplist, level = 0, containsselection) {
  if (!(jumplist instanceof Array)) {
    return (
      (jumplist.level > level
        ? times(
            `<${Jumplist.type}><li ${chapterDataAttrib(jumplist)} class="${[
              containsselection ? "containsselection" : "",
              jumplist.status
            ]
              .filter(Boolean)
              .join(" ")}"${ariaCurrentAttrib(jumplist)}>`,
            jumplist.level - level
          )
        : jumplist.level < level
          ? times(`</li></${Jumplist.type}>`, level - jumplist.level) +
            `<li ${chapterDataAttrib(jumplist)} class="${jumplist.status}"${ariaCurrentAttrib(jumplist)}>`
          : `<li ${chapterDataAttrib(jumplist)} class="${[
              containsselection ? "containsselection" : "",
              jumplist.status
            ]
              .filter(Boolean)
              .join(" ")}"${ariaCurrentAttrib(jumplist)}>`) +
      `<button${
        jumplist.groupid === null ? " disabled" : ""
      } type="submit" form="bb-q" name="gotonode" data-groupid="${
        jumplist.groupid
      }" ${chapterDataAttrib(jumplist)} value="${jumplist.groupid}" class="${
        jumplist.status
      }"><span class="bb-md-able">${escapeHTML(
        jumplist.screentitle.trim()
      )}</span></button>`
    );
  }
  if (jumplist.length == 0)
    return "</li>" + times(`</${Jumplist.type}></li>`, level);
  return (
    bakeInnerList(
      jumplist[0],
      level,
      hasselectedbelow(jumplist, jumplist[0].level)
    ) + bakeInnerList(jumplist.slice(1), jumplist[0].level)
  );
}
export const bakeAsListitems = jumplist => {
  let html = bakeInnerList(jumplist);
  // Odd case of indentation sans parent:
  if (["u", "o"].includes(html.charAt(1)))
    return '<li class="' + (jumplist.status || "") + '">' + html + "</li>";
  return html;
};
function bakeAsDropDown(jumplist, level = 0, containsselection) {
  // Implemented recursively so we can make the 'chapter' selected
  if (!(jumplist instanceof Array)) {
    return (
      "<option" +
      (jumplist.groupid === null ? " disabled" : "") +
      ' class="' +
      jumplist.status +
      '"' +
      ' groupid="' +
      jumplist.groupid +
      '"' +
      ' value="' +
      jumplist.groupid +
      '" ' +
      (containsselection || jumplist.status === "selected" ? "selected" : "") +
      " " +
      (jumplist.status === "selected" ? "disabled" : "") +
      ">" +
      times("&nbsp;", level * 2) +
      escapeHTML(jumplist.screentitle.trim()) +
      "</option>"
    );
  }
  if (jumplist.length == 0) return "";
  return (
    bakeAsDropDown(
      jumplist[0],
      level,
      hasselectedbelow(jumplist, jumplist[0].level)
    ) + bakeAsDropDown(jumplist.slice(1), jumplist[0].level)
  );
}

const findCurrentIndex = compose(
  i => (i === -1 ? Infinity : i),
  findIndex(({ status }) => status === "future")
);

export var Jumplist = new (function () {
  var _jumplist = []; //Save last jumplist
  this.type = "ul";
  this.draw = function draw(jumplist) {
    jumplist = jumplist || _jumplist;
    if (jumplist) _jumplist = jumplist;
    $(
      `.bb-jumplist ${Jumplist.type}, .bb-jumplist li, .bb-jumplist-dropdown select`
    ).remove();
    if (jumplist.length) {
      if (
        $(".bb-jumplist:visible, .bb-jumplist-dropdown:visible").length === 0
      ) {
        // If we can't rely on CSS to decide the appropriate type
        // of jumplist (happens sometimes onload), draw them both
        drawAsList(jumplist);
        drawAsDropDown(jumplist);
      } else {
        // Draw our standard list:
        if ($(".bb-jumplist").is(":visible")) drawAsList(jumplist);
        // Also draw the dropdown:
        if ($(".bb-jumplist-dropdown").is(":visible")) drawAsDropDown(jumplist);
      }
    }
  };

  function drawAsList(jumplist) {
    const currentIndex = findCurrentIndex(jumplist);
    $(".bb-jumplist").map((i, l) => {
      const slicetype = l.dataset["slice"];
      const itemizer = choose([
        [equals("before"), slice(0, currentIndex)],
        [equals("after"), slice(currentIndex, Infinity)],
        [T, identity]
      ])(slicetype);

      const html = bakeAsListitems(itemizer(jumplist));
      $(l).append(
        `<${Jumplist.type} aria-describedby="a-jumplist-title">${html}</${Jumplist.type}>`
      );
    });
    $(".bb-jumplist a:last").addClass("last");
    $(".bb-jumplist li:last-child").addClass("last-child"); // aid older browsers
  }
  function drawAsDropDown(jumplist) {
    if (jumplist.length) {
      var html = bakeAsDropDown(jumplist, 0);
      $(".bb-jumplist-dropdown").append("<select>" + html + "</select>");
    }
  }

  return this;
})();
